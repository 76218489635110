import {TestQuestion, TestQuestionSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getTestQuestions} from '../services/getTestQuestions'
import {storeTestQuestion} from '../services/storeTestQuestion'
import {updateTestQuestion} from '../services/updateTestQuestion'
import {getTestQuestion} from '../services/getTestQuestion'

const initialState: TestQuestionSchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined
}

export const testQuestionSlice = createSlice({
    name: 'testQuestion',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<TestQuestion>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTestQuestions.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getTestQuestions.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getTestQuestions.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getTestQuestion.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getTestQuestion.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getTestQuestion.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeTestQuestion.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeTestQuestion.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeTestQuestion.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateTestQuestion.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateTestQuestion.fulfilled, (state, action) => {
                state.isUpdating = false
            })
            .addCase(updateTestQuestion.rejected, (state, action) => {
                state.isUpdating = false
                //@ts-ignore
                state.updateErrors = action.payload
            })
    }
})

export const {actions: testQuestionActions} = testQuestionSlice
export const {reducer: testQuestionReducer} = testQuestionSlice
