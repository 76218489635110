import {createAsyncThunk} from "@reduxjs/toolkit";
import {APP_URL} from "src/shared/constants/api";
import {Country} from "src/entities/Country";

export const updateCountry = createAsyncThunk(
    'country/updateCountry',
    async (data: { id: number, name: string, flag?: any }, {rejectWithValue, getState}) => {
        try {
            // @ts-ignore
            const csrfToken = getState().auth.data.csrfToken
            const formData = new FormData()
            formData.append('name', data.name)
            formData.append('_method', 'PUT')
            if (data.flag) {
                formData.append('flag', data.flag)
            }

            const response = await fetch(APP_URL + `/api/admin/dictionaries/countries/${data.id}`, {
                method: 'POST',
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formData,
                credentials: 'include'
            })

            if (!response.ok) {
                // @ts-ignore
                const res = await response.json()
                return rejectWithValue(res.errors)
            } else {
                const res = await response.json()
            }
        } catch (error) {
            console.log({error})

            // @ts-ignore
            return rejectWithValue(error.response.data)
        }
    }
)
