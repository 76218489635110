import classes from './index.module.css'
import React, {CSSProperties, } from "react";
import Styled from 'styled-components';
import {Popover} from "src/shared/ui/Popup";
import {Link, useNavigate} from "react-router-dom";
import {userActions} from "src/entities/User";
import {logout} from "src/entities/Auth/model/services/logout";
import {RoutesPath} from "src/shared/config/routes";
import {useAppDispatch} from "src/shared/hooks/useAppDispatch";

const InfoWraper = Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .ninjadash-nav-actions__item{
        .ant-badge{
            .ant-badge-dot{
                top: 4px;
                left: 11px !important;
            }
        }
        &.ninjadash-nav-actions__message{
            .ant-badge{
                .ant-badge-dot{
                    background: #fff;
                }
            }
        }
        svg{
            color: gray;
        }
    }
    .ninjadash-nav-actions__nav-author{
        display: flex;
        margin: 0 5px;
        span, a{
            display: block;
            line-height: normal;
        }
    }
    .ninjadash-nav-actions__nav-author{
        a.ant-dropdown-trigger{
            img{
                max-width: 20px;
            }
        }
    }
`;

const UserDropDwon = Styled.div`
    .user-dropdwon{
        max-width: 280px;
        .user-dropdwon__info{
            display: flex;
            align-items: flex-start;
            padding: 20px 25px;
            border-radius: 8px;
            margin-bottom: 12px;
            background: $fff;
            img{
                margin-left: 15px;
            }
            figcaption{
                h1{
                    font-size: 14px;
                    margin-bottom: 2px;
                    color:  rgb(10,10,10);
                }
                p{
                    margin-bottom: 0px;
                    font-size: 13px;
                    color:  rgb(10,10,10);
                }
            }
        }
        .user-dropdwon__links{
            a{
                width: 100%;
                left: 0;
                right: 0;
                display: inline-flex;
                align-items: center;
                padding: 10px 12px;
                font-size: 14px;
                transition: .3s;
                color: rgb(10,10,10);
                &:hover{
                    background: rgba(130, 49, 211, 0.02);
                    color: rgb(130, 49, 211);
                    padding-left: 22px;
                }
                svg{
                    width: 16px;
                    transform: rotateY(0deg);
                    margin-right: 14px;
                }
            }
        }
        .user-dropdwon__bottomAction{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 500;
            text-align: center;
            position: relative;
            width: calc(100% + 30px);
            left: -15px;
            right: -15px;
            height: calc(100% + 15px);
            bottom: -15px;
            border-radius: 0 0 6px 6px;
            padding: 15px 0;
            background: rgb(244, 245, 247);
            color: rgb(116,116,116);
            svg{
                width: 15px;
                height: 15px;
                transition: color 0.3s;
                margin-left: 8px;
            }
            &:hover{
                color: #8231D3;
                svg{
                    color: #8231D3;
                }
            }
        }
    }
`;

const AuthInfo = (props: { username?: string, role?: string }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const onLogout = async () => {
        localStorage.removeItem('token') //TODO: Remove it when HTTP-only cookie will be used
        await dispatch(userActions.setAuthData(undefined))
        await dispatch(logout())
        navigate(RoutesPath.login)
    }

    const userContent = (
        <UserDropDwon>
            <div className="user-dropdwon">
                <figure className="user-dropdwon__info">
                    {/*<img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />*/}
                    <figcaption>
                        <h4>{props.username}</h4>
                        <p>{props.role}</p>
                    </figcaption>
                </figure>
                <Link className="user-dropdwon__bottomAction" onClick={onLogout} to="#">
                    Sign Out
                </Link>
            </div>
        </UserDropDwon>
    );

    return (
        <InfoWraper>
            <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
                <Popover placement="bottomRight" content={userContent} action="click">
                    <Link to="#" className="ninjadash-nav-action-link">
                        <span className="ninjadash-nav-actions__author--name">{props.username}</span>
                    </Link>
                </Popover>
            </div>
        </InfoWraper>
    )
}

export default AuthInfo
