import {Toast, ToastSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: ToastSchema = {
    data: undefined
}

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<Toast | undefined>) => {
            state.data = action.payload
        }
    }
})

export const {actions: toastActions} = toastSlice
export const {reducer: toastReducer} = toastSlice
