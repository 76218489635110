import {TutorCategory, TutorCategorySchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getTutorCategories} from "../services/getTutorCategories";
import {storeTutorCategory} from "../services/storeTutorCategory";
import {updateTutorCategory} from "../services/updateTutorCategory";
import {getTutorCategory} from "../services/getTutorCategory";

const initialState: TutorCategorySchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined
}

export const tutorCategorySlice = createSlice({
    name: 'tutorCategory',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<TutorCategory>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTutorCategories.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getTutorCategories.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getTutorCategories.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getTutorCategory.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getTutorCategory.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getTutorCategory.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeTutorCategory.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeTutorCategory.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeTutorCategory.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateTutorCategory.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateTutorCategory.fulfilled, (state, action) => {
                state.isUpdating = false
            })
            .addCase(updateTutorCategory.rejected, (state, action) => {
                state.isUpdating = false
                //@ts-ignore
                state.updateErrors = action.payload
            })
    }
})

export const {actions: tutorCategoryActions} = tutorCategorySlice
export const {reducer: tutorCategoryReducer} = tutorCategorySlice
