import classes from './index.module.css'
import React, {CSSProperties, forwardRef, MutableRefObject} from "react";
import {className} from "src/shared/utils/className";

export enum ButtonTypes {
    "BUTTON" = 'button',
    "RESET" = 'reset',
    "SUBMIT" = 'submit',
}

interface ButtonProps {
    children: any
    type?: ButtonTypes
    onClick?: (element: React.MouseEvent<HTMLButtonElement>) => void
    style?: CSSProperties
    themes?: Array<ButtonThemes>
    disabled?: boolean
    cls?: string
}

export enum ButtonThemes {
    'DEFAULT' = '',
    'ROUNDED' = 'rounded',
    'DECORATED' = 'decorated',
    'OUTLINE' = 'outline'
}

const Button = forwardRef((props: ButtonProps, ref) => {
    const {
        type = ButtonTypes.BUTTON,
        themes = [ButtonThemes.DEFAULT],
        disabled = false
    } = props
    const buttonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (props.onClick !== undefined) {
            props.onClick(e)
        }
    }

    return (
        <button
            ref={ref as MutableRefObject<any>}
            disabled={disabled}
            style={props.style ?? {}}
            //@ts-ignore
            className={className(classes.btn, {}, [...themes.map(theme => classes[theme]), props.cls])}
            type={type}
            onClick={(e) => buttonClicked(e)}
        >
            {props.children}
        </button>
    )
})

Button.displayName = 'Button'

export default Button
