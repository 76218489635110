import {TutorSchedule, TutorScheduleSchema} from "../../types";
import {getTutorSchedules} from '../services/getTutorSchedules'
import {storeTutorSchedule} from '../services/storeTutorSchedule'
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getTutorSchedule} from "../services/getTutorSchedule";
import {getAvailableTimes} from "../services/getAvailableTimes";

const initialState: TutorScheduleSchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined,
    isFetchingAvailableTimes: false
}

export const tutorScheduleSlice = createSlice({
    name: 'tutorSchedule',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<TutorSchedule>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTutorSchedules.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getTutorSchedules.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getTutorSchedules.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getTutorSchedule.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getTutorSchedule.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getTutorSchedule.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeTutorSchedule.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeTutorSchedule.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeTutorSchedule.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(getAvailableTimes.pending, (state, action) => {
                state.isFetchingAvailableTimes = true
            })
            .addCase(getAvailableTimes.fulfilled, (state, action) => {
                state.isFetchingAvailableTimes = false
            })
            .addCase(getAvailableTimes.rejected, (state, action) => {
                state.isFetchingAvailableTimes = false
            })
    }
})

export const {actions: tutorScheduleActions} = tutorScheduleSlice
export const {reducer: tutorScheduleReducer} = tutorScheduleSlice
