import {createAsyncThunk} from "@reduxjs/toolkit";
import {APP_URL} from "src/shared/constants/api";

export const getStudentRequest = createAsyncThunk(
    'user/getStudentRequest',
    async (data: {id: number}, {getState, rejectWithValue}) => {
        try {
            // @ts-ignore
            const csrfToken = getState().auth.data.csrfToken
            if (data.id) {
                let uri = `/api/moderator/books/${data.id}`

                const response = await fetch(APP_URL + uri, {
                    method: 'GET',
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    credentials: 'include'
                })

                if (!response.ok) {
                    // @ts-ignore
                    const res = await response.json()
                    console.log({data})
                    // return rejectWithValue(data.errors)
                } else {
                    const res = await response.json()
                    return res
                }
            }
        } catch (error) {
            console.log({error})

            // @ts-ignore
            return rejectWithValue(error.response.data)
        }
    }
)
