import {Content, PopoverStyle, Title} from "src/shared/ui/PopoverStyle";
import React from "react";

interface PopoverInterface extends React.PropsWithChildren{
        content: any
        placement: any
        title?: string
        action: any
}
function Popover(props: PopoverInterface) {
        const { content, placement, title, action, children } = props;
        const content1 = <Content>{content}</Content>;

        return (
            <PopoverStyle placement={placement} title={title && <Title>{title}</Title>} content={content1} trigger={action}>
                    {children}
            </PopoverStyle>
        );
}

export default Popover
