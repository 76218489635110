import {createAsyncThunk} from "@reduxjs/toolkit";
import {APP_URL} from "src/shared/constants/api";

export const storeTutorRequest = createAsyncThunk(
    'user/storeTutorRequest',
    async (data: {data: any, hasFile?: boolean}, {rejectWithValue, getState}) => {
        try {
            // @ts-ignore
            const csrfToken = getState().auth.data.csrfToken
            const headers: {[key: string]: string} = {
                'X-XSRF-TOKEN': csrfToken,
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
            let body = data.data
            if (!data.hasFile) {
                headers['Content-Type'] = 'application/json'
                body = JSON.stringify(body)
            }

            const response = await fetch(APP_URL + '/api/tutor-request', {
                method: 'POST',
                headers,
                body,
                credentials: 'include'
            })

            if (!response.ok) {
                // @ts-ignore
                const res = await response.json()
                return rejectWithValue(res.errors)
            } else {
                const res = await response.json()
                return res
            }
        } catch (error) {
            console.log({error})

            // @ts-ignore
            return rejectWithValue(error.response.data)
        }
    }
)
