import React from "react";
import {ProjectLayouts} from "src/shared/config/layouts";
import {Roles} from "./roles";
import {MainPage} from "src/pages/Main";
import {UnauthorizedPage} from "src/pages/Unauthorized";
import {LoginPage} from "src/pages/Login";
import {TutorsListPage} from "src/pages/TutorsList";
import {CountriesListPage} from "src/pages/CountriesList";
import {CountriesCreatePage} from "src/pages/CountriesCreate";
import {CountriesEditPage} from "src/pages/CountriesEdit";
import {SubjectsListPage} from "src/pages/SubjectsList";
import {SubjectsCreatePage} from "src/pages/SubjectsCreate";
import {SubjectsEditPage} from "src/pages/SubjectsEdit";
import {LanguagesListPage} from "src/pages/LanguagesList";
import {LanguagesCreatePage} from "src/pages/LanguagesCreate";
import {LanguagesEditPage} from "src/pages/LanguagesEdit";
import {TutorCategoriesListPage} from "src/pages/TutorCategoriesList";
import {TutorCategoriesCreatePage} from "src/pages/TutorCategoriesCreate";
import {TutorCategoriesEditPage} from "src/pages/TutorCategoriesEdit";
import {UsersListPage} from "src/pages/UsersList";
import {UsersCreatePage} from "src/pages/UsersCreate";
import {UsersEditPage} from "src/pages/UsersEdit";
import {LiteraturesListPage} from "src/pages/LiteraturesList";
import {LiteraturesCreatePage} from "src/pages/LiteraturesCreate";
import {LiteraturesEditPage} from "src/pages/LiteraturesEdit";
import {TestsListPage} from "src/pages/TestsList";
import {TestsCreatePage} from "src/pages/TestsCreate";
import {TestsEditPage} from "src/pages/TestsEdit";
import {TestQuestionsListPage} from "src/pages/TestQuestionsList";
import {TestQuestionsCreatePage} from "src/pages/TestQuestionsCreate";
import {TestQuestionsEditPage} from "src/pages/TestQuestionsEdit";
import {NotFoundPage} from "src/pages/NotFound";
import {GetStartedPage} from "src/pages/GetStarted";
import {StepperPage} from "src/pages/Stepper";
import {TestQuestionAnswersListPage} from "src/pages/TestQuestionAnswersList";
import {TestQuestionAnswersCreatePage} from "src/pages/TestQuestionAnswersCreate";
import {TestQuestionAnswersEditPage} from "src/pages/TestQuestionAnswersEdit";
import {RegisterStudentPage} from "src/pages/RegisterStudent";
import {RegisterTutorPage} from "src/pages/RegisterTutor";
import {LanguageLevelsListPage} from "src/pages/LanguageLevelsList";
import {LanguageLevelsCreatePage} from "src/pages/LanguageLevelsCreate";
import {LanguageLevelsEditPage} from "src/pages/LanguageLevelsEdit";
import {MainTutorPage} from "src/pages/MainTutor";
import {MainAdminPage} from "src/pages/MainAdmin";
import {TutorStepperPage} from "src/pages/TutorStepper";
import {MainModeratorPage} from "src/pages/MainModerator";
import {ModeratorTutorsListPage} from "src/pages/ModeratorTutorsList";
import {ModeratorTutorApprovePage} from "src/pages/ModeratorTutorApprove";
import { ModeratorReportsPage } from "src/pages/ModeratorReports";
import {TutorSchedulesListPage} from "src/pages/TutorSchedulesList";
import {TutorSchedulesCreatePage} from "src/pages/TutorSchedulesCreate";
import {TutorGroupLessonsListPage} from "src/pages/TutorGroupLessonsList";
import {TutorGroupLessonsCreatePage} from "src/pages/TutorGroupLessonsCreate";
import {TutorGroupLessonsEditPage} from "src/pages/TutorGroupLessonsEdit";
import {TutorsShowPage} from "src/pages/TutorsShow";
import {CheckoutPage} from "src/pages/Checkout";
import {MainStudentPage} from "src/pages/MainStudent";
import {ModeratorStudentsListPage} from "src/pages/ModeratorStudentsList";
import {ModeratorStudentApprovePage} from "src/pages/ModeratorStudentApprove";
import {TutorLessonPage} from "src/pages/TutorLesson";
import {StudentLessonPage} from "src/pages/StudentLesson";
import {StudentTestsListPage} from "src/pages/StudentTestsList";
import {StudentTestsShowPage} from "src/pages/StudentTestsShow";
import {StudentLiteraturesListPage} from "src/pages/StudentLiteraturesList";
import {TutorLiteraturesListPage} from "src/pages/TutorLiteraturesList";
import {TutorTestsListPage} from "src/pages/TutorTestsList";
import {TutorTestsShowPage} from "src/pages/TutorTestsShow";
import {StudentApproveLessonShow} from "src/pages/StudentApproveLessonShow";
import {StudentLessonShow} from "src/pages/StudentLessonShow";
import {TutorStudentsListPage} from "src/pages/TutorStudentsList";
import {TutorProfilePage} from "src/pages/TutorProfile";

export type RoutesConfigItem = {
    element: React.JSX.Element
    require_auth: boolean
    layout?: ProjectLayouts
    path: string
    roles: Array<Roles>
    allow_with_auth: boolean
}

export enum AppRoutes {
    'MAIN' = 'main',
    'MAIN_ADMIN' = 'main_admin',
    'MAIN_TUTOR' = 'main_tutor',
    'MAIN_STUDENT' = 'main_student',
    'MAIN_MODERATOR' = 'main_moderator',
    'LOGIN' = 'login',
    'REGISTER_STUDENT' = 'register_student',
    'REGISTER_TUTOR' = 'register_tutor',
    'UNAUTHORIZED' = 'unauthorized',
    'TUTORS' = 'tutors',
    'TUTORS_SHOW' = 'tutors_show',
    'COUNTRIES' = 'countries',
    'COUNTRIES_CREATE' = 'countries_create',
    'COUNTRIES_EDIT' = 'countries_edit',
    'SUBJECTS' = 'subjects',
    // 'SUBJECTS_CREATE' = 'subjects_create',
    // 'SUBJECTS_EDIT' = 'subjects_edit',
    'LANGUAGES' = 'languages',
    'LANGUAGES_CREATE' = 'languages_create',
    'LANGUAGES_EDIT' = 'languages_edit',
    'TUTOR_CATEGORIES' = 'tutor_categories',
    'TUTOR_CATEGORIES_CREATE' = 'tutor_categories_create',
    'TUTOR_CATEGORIES_EDIT' = 'tutor_categories_edit',
    'USERS' = 'users',
    'USERS_CREATE' = 'users_create',
    'USERS_EDIT' = 'users_edit',
    'LITERATURES' = 'literatures',
    'LITERATURES_CREATE' = 'literatures_create',
    'LITERATURES_EDIT' = 'literatures_edit',
    'TESTS' = 'tests',
    'TESTS_CREATE' = 'tests_create',
    'TESTS_EDIT' = 'tests_edit',
    'TEST_QUESTIONS' = 'test_questions',
    'TEST_QUESTIONS_CREATE' = 'test_questions_create',
    'TEST_QUESTIONS_EDIT' = 'test_questions_edit',
    'TEST_QUESTION_ANSWERS' = 'test_question_answers',
    'TEST_QUESTION_ANSWERS_CREATE' = 'test_question_answers_create',
    'TEST_QUESTION_ANSWERS_EDIT' = 'test_question_answers_edit',
    'LANGUAGE_LEVEL' = 'language_level',
    'LANGUAGE_LEVEL_CREATE' = 'language_level_create',
    'LANGUAGE_LEVEL_EDIT' = 'language_level_edit',
    'MODERATOR_TUTOR_LIST' = 'moderator_tutor_list',
    'MODERATOR_TUTOR_APPROVE' = 'moderator_tutor_approve',
    'MODERATOR_STUDENT_LIST' = 'moderator_student_list',
    'MODERATOR_REPORTS' = 'moderator_reports',
    'MODERATOR_STUDENT_APPROVE' = 'moderator_student_approve',
    'TUTOR_SCHEDULE' = 'tutor_schedule',
    'TUTOR_SCHEDULE_CREATE' = 'tutor_schedule_create',
    'TUTOR_GROUP_LESSONS' = 'tutor_group_lessons',
    'TUTOR_GROUP_LESSONS_CREATE' = 'tutor_group_lessons_create',
    'TUTOR_GROUP_LESSONS_EDIT' = 'tutor_group_lessons_edit',
    'NOT_FOUND' = 'not_found',
    'GET_STARTED' = 'get_started',
    'CHECKOUT' = 'checkout',
    'STEPPER' = 'stepper',
    'TUTOR_STEPPER' = 'tutor_stepper',
    'TUTOR_LESSON' = 'tutor_lesson',
    'STUDENT_LESSON' = 'student_lesson',
    'STUDENT_TESTS' = 'student_tests',
    'STUDENT_TESTS_SHOW' = 'student_tests_show',
    'STUDENT_LITERATURES' = 'student_literatures',
    'TUTOR_LITERATURES' = 'tutor_literatures',
    'TUTOR_STUDENTS' = 'tutor_students',
    'TUTOR_TESTS' = 'tutor_tests',
    'TUTOR_TESTS_SHOW' = 'tutor_tests_show',
    'STUDENT_APPROVE_LESSON_SHOW' = 'student_approve_lesson_show',
    'STUDENT_LESSON_SHOW' = 'student_lesson_show',
    'TUTOR_PROFILE' = 'tutor_profile',
}

export const RoutesPath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.MAIN_ADMIN]: '/admin',
    [AppRoutes.MAIN_TUTOR]: '/tutor',
    [AppRoutes.MAIN_STUDENT]: '/student',
    [AppRoutes.MAIN_MODERATOR]: '/moderator',
    [AppRoutes.LOGIN]: '/login',
    [AppRoutes.REGISTER_STUDENT]: '/register-student',
    [AppRoutes.REGISTER_TUTOR]: '/register-tutor',
    [AppRoutes.UNAUTHORIZED]: '/unauthorized',
    [AppRoutes.TUTORS]: '/tutors',
    [AppRoutes.TUTORS_SHOW]: '/tutors/:id/show',
    [AppRoutes.COUNTRIES]: '/admin/countries',
    [AppRoutes.COUNTRIES_CREATE]: '/admin/countries/create',
    [AppRoutes.COUNTRIES_EDIT]: '/admin/countries/:id/edit',
    [AppRoutes.SUBJECTS]: '/admin/subjects',
    // [AppRoutes.SUBJECTS_CREATE]: '/admin/subjects/create',
    // [AppRoutes.SUBJECTS_EDIT]: '/admin/subjects/:id/edit',
    [AppRoutes.LANGUAGES]: '/admin/languages',
    [AppRoutes.LANGUAGES_CREATE]: '/admin/languages/create',
    [AppRoutes.LANGUAGES_EDIT]: '/admin/languages/:id/edit',
    [AppRoutes.TUTOR_CATEGORIES]: '/admin/tutor-categories',
    [AppRoutes.TUTOR_CATEGORIES_CREATE]: '/admin/tutor-categories/create',
    [AppRoutes.TUTOR_CATEGORIES_EDIT]: '/admin/tutor-categories/:id/edit',
    [AppRoutes.USERS]: '/admin/users',
    [AppRoutes.USERS_CREATE]: '/admin/users/create',
    [AppRoutes.USERS_EDIT]: '/admin/users/:id/edit',
    [AppRoutes.LITERATURES]: '/admin/literatures',
    [AppRoutes.LITERATURES_CREATE]: '/admin/literatures/create',
    [AppRoutes.LITERATURES_EDIT]: '/admin/literatures/:id/edit',
    [AppRoutes.TESTS]: '/admin/tests',
    [AppRoutes.TESTS_CREATE]: '/admin/tests/create',
    [AppRoutes.TESTS_EDIT]: '/admin/tests/:id/edit',
    [AppRoutes.TEST_QUESTIONS]: '/admin/tests/:id/questions',
    [AppRoutes.TEST_QUESTIONS_CREATE]: '/admin/tests/:id/questions/create',
    [AppRoutes.TEST_QUESTIONS_EDIT]: '/admin/tests/:id/questions/:question_id/edit',
    [AppRoutes.TEST_QUESTION_ANSWERS]: '/admin/tests/:id/questions/:question_id/answers',
    [AppRoutes.TEST_QUESTION_ANSWERS_CREATE]: '/admin/tests/:id/questions/:question_id/answers/create',
    [AppRoutes.TEST_QUESTION_ANSWERS_EDIT]: '/admin/tests/:id/questions/:question_id/answers/:answer_id/edit',
    [AppRoutes.LANGUAGE_LEVEL]: '/admin/language-levels',
    [AppRoutes.LANGUAGE_LEVEL_CREATE]: '/admin/language-levels/create',
    [AppRoutes.LANGUAGE_LEVEL_EDIT]: '/admin/language-levels/:id/edit',
    [AppRoutes.MODERATOR_TUTOR_LIST]: '/moderator/tutors',
    [AppRoutes.MODERATOR_TUTOR_APPROVE]: '/moderator/tutors/:id/approve',
    [AppRoutes.MODERATOR_STUDENT_LIST]: '/moderator/students',
    [AppRoutes.MODERATOR_REPORTS]: '/moderator/reports',
    [AppRoutes.MODERATOR_STUDENT_APPROVE]: '/moderator/students/:id/approve',
    [AppRoutes.TUTOR_SCHEDULE]: '/tutor/schedules',
    [AppRoutes.TUTOR_SCHEDULE_CREATE]: '/tutor/schedules/create',
    [AppRoutes.TUTOR_GROUP_LESSONS]: '/tutor/group-lessons',
    [AppRoutes.TUTOR_GROUP_LESSONS_CREATE]: '/tutor/group-lessons/create',
    [AppRoutes.TUTOR_GROUP_LESSONS_EDIT]: '/tutor/group-lessons/:id/edit',
    [AppRoutes.NOT_FOUND]: '/*',
    [AppRoutes.GET_STARTED]: '/get-started',
    [AppRoutes.CHECKOUT]: '/checkout/:id',
    [AppRoutes.STEPPER]: '/stepper',
    [AppRoutes.TUTOR_STEPPER]: '/tutor-stepper',
    [AppRoutes.TUTOR_LESSON]: '/tutor/lessons/:id',
    [AppRoutes.STUDENT_LESSON]: '/student/lessons/:id',
    [AppRoutes.STUDENT_TESTS]: '/student-tests/:id',
    [AppRoutes.STUDENT_TESTS_SHOW]: '/student/tests/:id/show',
    [AppRoutes.STUDENT_LITERATURES]: '/student/literatures',
    [AppRoutes.TUTOR_LITERATURES]: '/tutor/literatures',
    [AppRoutes.TUTOR_STUDENTS]: '/tutor/student-tests',
    [AppRoutes.TUTOR_TESTS]: '/tutor/student-tests/:id',
    [AppRoutes.TUTOR_TESTS_SHOW]: '/tutor/student-tests/:id/tests/:testId/show',
    [AppRoutes.STUDENT_APPROVE_LESSON_SHOW]: '/student/approving-lessons/:id/show',
    [AppRoutes.STUDENT_LESSON_SHOW]: '/student/lessons/:id/show',
    [AppRoutes.TUTOR_PROFILE]: '/tutor/profile',
}

export const RoutesConfig: Record<AppRoutes, RoutesConfigItem> = {
    [AppRoutes.MAIN]: {
        path: RoutesPath.main,
        element: <MainPage />,
        allow_with_auth: true,
        require_auth: false,
        layout: undefined,
        roles: [],
    },
    [AppRoutes.MAIN_ADMIN]: {
        path: RoutesPath.main_admin,
        element: <MainAdminPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.MAIN_TUTOR]: {
        path: RoutesPath.main_tutor,
        element: <MainTutorPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.MAIN_STUDENT]: {
        path: RoutesPath.main_student,
        element: <MainStudentPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.STUDENT],
    },
    [AppRoutes.MAIN_MODERATOR]: {
        path: RoutesPath.main_moderator,
        element: <MainModeratorPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.MODERATOR],
    },
    [AppRoutes.LOGIN]: {
        path: RoutesPath.login,
        element: <LoginPage />,
        allow_with_auth: false,
        require_auth: false,
        layout: ProjectLayouts.AuthLayout,
        roles: [],
    },
    [AppRoutes.REGISTER_STUDENT]: {
        path: RoutesPath.register_student,
        element: <RegisterStudentPage />,
        allow_with_auth: false,
        require_auth: false,
        layout: ProjectLayouts.AuthLayout,
        roles: [],
    },
    [AppRoutes.REGISTER_TUTOR]: {
        path: RoutesPath.register_tutor,
        element: <RegisterTutorPage />,
        allow_with_auth: false,
        require_auth: false,
        layout: ProjectLayouts.AuthLayout,
        roles: [],
    },
    [AppRoutes.UNAUTHORIZED]: {
        path: RoutesPath.unauthorized,
        element: <UnauthorizedPage />,
        allow_with_auth: true,
        require_auth: false,
        layout: ProjectLayouts.AuthLayout,
        roles: [],
    },
    [AppRoutes.TUTORS]: {
        path: RoutesPath.tutors,
        element: <TutorsListPage />,
        allow_with_auth: true,
        require_auth: false,
        roles: [],
    },
    [AppRoutes.TUTORS_SHOW]: {
        path: RoutesPath.tutors_show,
        element: <TutorsShowPage />,
        allow_with_auth: true,
        require_auth: false,
        roles: [],
    },
    [AppRoutes.COUNTRIES]: {
        path: RoutesPath.countries,
        element: <CountriesListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.COUNTRIES_CREATE]: {
        path: RoutesPath.countries_create,
        element: <CountriesCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.COUNTRIES_EDIT]: {
        path: RoutesPath.countries_edit,
        element: <CountriesEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.SUBJECTS]: {
        path: RoutesPath.subjects,
        element: <SubjectsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    // [AppRoutes.SUBJECTS_CREATE]: {
    //     path: RoutesPath.subjects_create,
    //     element: <SubjectsCreatePage />,
    //     allow_with_auth: true,
    //     require_auth: true,
    //     layout: ProjectLayouts.AppLayout,
    //     roles: [Roles.ADMIN],
    // },
    // [AppRoutes.SUBJECTS_EDIT]: {
    //     path: RoutesPath.subjects_edit,
    //     element: <SubjectsEditPage />,
    //     allow_with_auth: true,
    //     require_auth: true,
    //     layout: ProjectLayouts.AppLayout,
    //     roles: [Roles.ADMIN],
    // },
    [AppRoutes.LANGUAGES]: {
        path: RoutesPath.languages,
        element: <LanguagesListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.LANGUAGES_CREATE]: {
        path: RoutesPath.languages_create,
        element: <LanguagesCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.LANGUAGES_EDIT]: {
        path: RoutesPath.languages_edit,
        element: <LanguagesEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TUTOR_CATEGORIES]: {
        path: RoutesPath.tutor_categories,
        element: <TutorCategoriesListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TUTOR_CATEGORIES_CREATE]: {
        path: RoutesPath.tutor_categories_create,
        element: <TutorCategoriesCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TUTOR_CATEGORIES_EDIT]: {
        path: RoutesPath.tutor_categories_edit,
        element: <TutorCategoriesEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.USERS]: {
        path: RoutesPath.users,
        element: <UsersListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.USERS_CREATE]: {
        path: RoutesPath.users_create,
        element: <UsersCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.USERS_EDIT]: {
        path: RoutesPath.users_edit,
        element: <UsersEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.LITERATURES]: {
        path: RoutesPath.literatures,
        element: <LiteraturesListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.LITERATURES_CREATE]: {
        path: RoutesPath.literatures_create,
        element: <LiteraturesCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.LITERATURES_EDIT]: {
        path: RoutesPath.literatures_edit,
        element: <LiteraturesEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TESTS]: {
        path: RoutesPath.tests,
        element: <TestsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TESTS_CREATE]: {
        path: RoutesPath.tests_create,
        element: <TestsCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TESTS_EDIT]: {
        path: RoutesPath.tests_edit,
        element: <TestsEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TEST_QUESTIONS]: {
        path: RoutesPath.test_questions,
        element: <TestQuestionsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TEST_QUESTIONS_CREATE]: {
        path: RoutesPath.test_questions_create,
        element: <TestQuestionsCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TEST_QUESTIONS_EDIT]: {
        path: RoutesPath.test_questions_edit,
        element: <TestQuestionsEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TEST_QUESTION_ANSWERS]: {
        path: RoutesPath.test_question_answers,
        element: <TestQuestionAnswersListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TEST_QUESTION_ANSWERS_CREATE]: {
        path: RoutesPath.test_question_answers_create,
        element: <TestQuestionAnswersCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.TEST_QUESTION_ANSWERS_EDIT]: {
        path: RoutesPath.test_question_answers_edit,
        element: <TestQuestionAnswersEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.LANGUAGE_LEVEL]: {
        path: RoutesPath.language_level,
        element: <LanguageLevelsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.LANGUAGE_LEVEL_CREATE]: {
        path: RoutesPath.language_level_create,
        element: <LanguageLevelsCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.LANGUAGE_LEVEL_EDIT]: {
        path: RoutesPath.language_level_edit,
        element: <LanguageLevelsEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.ADMIN],
    },
    [AppRoutes.MODERATOR_TUTOR_LIST]: {
        path: RoutesPath.moderator_tutor_list,
        element: <ModeratorTutorsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.MODERATOR],
    },
    [AppRoutes.MODERATOR_TUTOR_APPROVE]: {
        path: RoutesPath.moderator_tutor_approve,
        element: <ModeratorTutorApprovePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.MODERATOR],
    },
    [AppRoutes.MODERATOR_STUDENT_LIST]: {
        path: RoutesPath.moderator_student_list,
        element: <ModeratorStudentsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.MODERATOR],
    },
    [AppRoutes.MODERATOR_REPORTS]: {
        path: RoutesPath.moderator_reports,
        element: <ModeratorReportsPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.MODERATOR],
    },
    [AppRoutes.MODERATOR_STUDENT_APPROVE]: {
        path: RoutesPath.moderator_student_approve,
        element: <ModeratorStudentApprovePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.MODERATOR],
    },
    [AppRoutes.TUTOR_SCHEDULE]: {
        path: RoutesPath.tutor_schedule,
        element: <TutorSchedulesListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.TUTOR_SCHEDULE_CREATE]: {
        path: RoutesPath.tutor_schedule_create,
        element: <TutorSchedulesCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.TUTOR_GROUP_LESSONS]: {
        path: RoutesPath.tutor_group_lessons,
        element: <TutorGroupLessonsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.TUTOR_GROUP_LESSONS_CREATE]: {
        path: RoutesPath.tutor_group_lessons_create,
        element: <TutorGroupLessonsCreatePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.TUTOR_GROUP_LESSONS_EDIT]: {
        path: RoutesPath.tutor_group_lessons_edit,
        element: <TutorGroupLessonsEditPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.GET_STARTED]: {
        path: RoutesPath.get_started,
        element: <GetStartedPage />,
        allow_with_auth: true,
        require_auth: false,
        layout: ProjectLayouts.AuthLayout,
        roles: [],
    },
    [AppRoutes.STEPPER]: {
        path: RoutesPath.stepper,
        element: <StepperPage />,
        allow_with_auth: true,
        require_auth: false,
        layout: ProjectLayouts.AppLayout,
        roles: [],
    },
    [AppRoutes.CHECKOUT]: {
        path: RoutesPath.checkout,
        element: <CheckoutPage />,
        allow_with_auth: true,
        require_auth: false,
        roles: [],
    },
    [AppRoutes.TUTOR_STEPPER]: {
        path: RoutesPath.tutor_stepper,
        element: <TutorStepperPage />,
        allow_with_auth: true,
        require_auth: true,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.TUTOR_LESSON]: {
        path: RoutesPath.tutor_lesson,
        element: <TutorLessonPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.STUDENT_LESSON]: {
        path: RoutesPath.student_lesson,
        element: <StudentLessonPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.STUDENT],
    },
    [AppRoutes.STUDENT_TESTS]: {
        path: RoutesPath.student_tests,
        element: <StudentTestsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.STUDENT],
    },
    [AppRoutes.STUDENT_TESTS_SHOW]: {
        path: RoutesPath.student_tests_show,
        element: <StudentTestsShowPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.STUDENT],
    },
    [AppRoutes.STUDENT_LITERATURES]: {
        path: RoutesPath.student_literatures,
        element: <StudentLiteraturesListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.STUDENT],
    },
    [AppRoutes.TUTOR_LITERATURES]: {
        path: RoutesPath.tutor_literatures,
        element: <TutorLiteraturesListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.TUTOR_STUDENTS]: {
        path: RoutesPath.tutor_students,
        element: <TutorStudentsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.TUTOR_TESTS]: {
        path: RoutesPath.tutor_tests,
        element: <TutorTestsListPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.TUTOR_TESTS_SHOW]: {
        path: RoutesPath.tutor_tests_show,
        element: <TutorTestsShowPage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.STUDENT_APPROVE_LESSON_SHOW]: {
        path: RoutesPath.student_approve_lesson_show,
        element: <StudentApproveLessonShow />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.STUDENT],
    },
    [AppRoutes.STUDENT_LESSON_SHOW]: {
        path: RoutesPath.student_lesson_show,
        element: <StudentLessonShow />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.STUDENT],
    },
    [AppRoutes.TUTOR_PROFILE]: {
        path: RoutesPath.tutor_profile,
        element: <TutorProfilePage />,
        allow_with_auth: true,
        require_auth: true,
        layout: ProjectLayouts.AppLayout,
        roles: [Roles.TUTOR],
    },
    [AppRoutes.NOT_FOUND]: {
        path: RoutesPath.not_found,
        element: <NotFoundPage />,
        allow_with_auth: true,
        require_auth: false,
        layout: ProjectLayouts.AuthLayout,
        roles: [],
    },
}
