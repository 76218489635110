import {Literature, LiteratureSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getLiteratures} from "../services/getLiteratures";
import {storeLiterature} from "../services/storeLiterature";
import {updateLiterature} from "../services/updateLiterature";
import {getLiterature} from "../services/getLiterature";

const initialState: LiteratureSchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined
}

export const literatureSlice = createSlice({
    name: 'literature',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<Literature>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getLiteratures.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getLiteratures.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getLiteratures.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getLiterature.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getLiterature.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getLiterature.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeLiterature.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeLiterature.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeLiterature.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateLiterature.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateLiterature.fulfilled, (state, action) => {
                state.isUpdating = false
            })
            .addCase(updateLiterature.rejected, (state, action) => {
                state.isUpdating = false
                //@ts-ignore
                state.updateErrors = action.payload
            })
    }
})

export const {actions: literatureActions} = literatureSlice
export const {reducer: literatureReducer} = literatureSlice
