import React from 'react';

const UilEllipsisV = (props: {color?: any, size?: any}) => {
    const { color, size, ...otherProps } = props
    return React.createElement('svg', {
        xmlns: 'http://www.w3.org/2000/svg',
        width: size,
        height: size,
        viewBox: '0 0 24 24',
        fill: color,
        ...otherProps
    }, React.createElement('path', {
        d: 'M12,7a2,2,0,1,0-2-2A2,2,0,0,0,12,7Zm0,10a2,2,0,1,0,2,2A2,2,0,0,0,12,17Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z'
    }));
};

export default UilEllipsisV;
