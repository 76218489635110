import {Test, TestSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getTests} from '../services/getTests'
import {storeTest} from '../services/storeTest'
import {updateTest} from '../services/updateTest'
import {getTest} from '../services/getTest'

const initialState: TestSchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined
}

export const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<Test>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTests.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getTests.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getTests.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getTest.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getTest.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getTest.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeTest.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeTest.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeTest.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateTest.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateTest.fulfilled, (state, action) => {
                state.isUpdating = false
            })
            .addCase(updateTest.rejected, (state, action) => {
                state.isUpdating = false
                //@ts-ignore
                state.updateErrors = action.payload
            })
    }
})

export const {actions: testActions} = testSlice
export const {reducer: testReducer} = testSlice
