import classes from './index.module.css'
import {useSelector} from "react-redux";
import {getAllTutorLessons, getAuthUserData, getIsFetchingAllTutorLessons} from "src/entities/User";
import {Calendar, momentLocalizer, View} from "react-big-calendar";
import moment from 'moment'
import {Link, useNavigate} from "react-router-dom";
import {RoutesConfig} from "src/shared/config/routes";
import {EventProp} from "src/pages/MainStudent";
import {useEffect, useMemo, useState} from "react";
import {useAppDispatch} from "src/shared/hooks/useAppDispatch";
import {ReactComponent as Fetching} from "src/shared/assets/icons/loading.svg"
import {CalendarWrapper, Main} from "./styled";
import {Col, Modal, Row} from "antd";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import {ReactComponent as List} from 'src/shared/assets/icons/list.svg'

const Localizer = momentLocalizer(moment)

const DragAndDropCalendar = withDragAndDrop(Calendar);

const MainTutorPage = () => {
    const navigate = useNavigate()
    const [lessons, setLessons] = useState<Array<any>>([])
    const dispatch = useAppDispatch()
    const isFetchingLessons = useSelector(getIsFetchingAllTutorLessons)

    const onSelect = (e: any) => {
        navigate(RoutesConfig.tutor_lesson.path.replace(':id', `${e.id}`))
    }

    const lessonsEvent = useMemo(() => {
        const data: Array<EventProp> = []
        lessons.forEach((lesson: any) => {
            data.push({
                start: new Date(`${lesson.date}T${lesson.start_time}`),
                end: new Date(`${lesson.date}T${lesson.end_time}`),
                title: lesson.subject.name,
                id: lesson.id
            })
        })

        return data
    }, [lessons])

    useEffect(() => {
        const fetchLessons = async () => {
            const data = await dispatch(getAllTutorLessons())
            if (data.type.includes('fulfilled')) {
                setLessons(data.payload)
            }
        }
        fetchLessons()
    }, [])

    const eventStyleGetter = (event: any) => {
        return {
            className: event.label,
        };
    };

    const calendar = useMemo(() => {
      if (window.innerWidth < 1000) {
          return (
              <DragAndDropCalendar
                  className="ninjadasgcaled"
                  selectable
                  localizer={Localizer}
                  events={lessonsEvent}
                  resizable
                  // eslint-disable-next-line no-use-before-define
                  components={{ toolbar: CustomToolbar }}
                  defaultView={'day'}
                  views={['day', 'agenda']}
                  defaultDate={new Date()}
                  step={60}
                  onSelectEvent={onSelect}
                  eventPropGetter={eventStyleGetter}
                  formats={{
                      timeGutterFormat: 'HH:mm'
                  }}
              />
          )
      } else {
          return (
              <DragAndDropCalendar
                  className="ninjadasgcaled"
                  selectable
                  localizer={Localizer}
                  events={lessonsEvent}
                  resizable
                  // eslint-disable-next-line no-use-before-define
                  components={{ toolbar: CustomToolbar }}
                  defaultView={'week'}
                  views={['week', 'day', 'agenda']}
                  defaultDate={new Date()}
                  step={60}
                  onSelectEvent={onSelect}
                  eventPropGetter={eventStyleGetter}
                  formats={{
                      timeGutterFormat: 'HH:mm'
                  }}
              />
          )
      }
    }, [window.innerWidth, lessons])

    return (
        <div className={'main-page'}>
            {isFetchingLessons ? (
                <Fetching className={classes.fetching} />
                ) : (
                <Main>
                    <CalendarWrapper className="ninjadash-calendar-wrap">
                        <Row gutter={25}>
                            <Col xxl={18} xl={15} xs={24}>
                                {calendar}
                            </Col>
                        </Row>
                    </CalendarWrapper>
                </Main>
            )}
        </div>
    )
}

export const CustomToolbar = (props: any) => {
    const [showWeekLabel, setShowWeekLabel] = useState<boolean>(true)
    const { label } = props;

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1000) {
                setShowWeekLabel(false)
            } else {
                setShowWeekLabel(true)
            }
        };

        // Set initial view on load
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="calendar-header">
            <div className="calendar-header__left">
                <button type="button" className="btn-today" onClick={() => props.onNavigate('TODAY')}>
                    today
                </button>
                <div className="calender-head__navigation">
                    <button className="btn-navigate" type="button" onClick={() => props.onNavigate('PREV')} aria-label="Previous">
                        <svg
                            width={20}
                            height={20}
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
                        </svg>
                    </button>
                    <span className="date-label">{label}</span>
                    <button className="btn-navigate" type="button" onClick={() => props.onNavigate('NEXT')} aria-label="Next">
                        <svg width={20}
                             height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="calendar-header__right">
                <ul>
                    {showWeekLabel ? (
                        <li>
                            <Link
                                className={props.view === 'week' ? 'active' : ''}
                                to="#"
                                onClick={() => props.onView('week')}
                            >
                                Week
                            </Link>
                        </li>
                    ) : null}
                    <li>
                        <Link
                            className={props.view === 'day' ? 'active' : ''}
                            to="#"
                            onClick={() => props.onView('day')}
                        >
                            Day
                        </Link>
                    </li>
                </ul>
                <Link
                    className={`schedule-list ${props.view === 'agenda' ? 'active' : ''}`}
                    to="#"
                    onClick={() => props.onView('agenda')}
                >
                    <List width={20} height={20} />
                    Schedule
                </Link>
            </div>
        </div>
    );
};

export default MainTutorPage
