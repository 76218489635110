import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {TutorSchedule} from "../../types";
import {InputError} from "src/entities/Auth";
import {Pagination} from "src/entities/Country";

export const getData = (state: StateSchema): Array<TutorSchedule> => state.tutorSchedule.data
export const getPagination = (state: StateSchema): Pagination => state.tutorSchedule.pagination
export const getFetching = (state: StateSchema): boolean => state.tutorSchedule.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.tutorSchedule.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.tutorSchedule.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.tutorSchedule.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.tutorSchedule.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.tutorSchedule.updateErrors
export const getIsFetchingAvailableTimes = (state: StateSchema): boolean => state.tutorSchedule.isFetchingAvailableTimes
