import Styled from 'styled-components';

const Main = Styled.div`
    padding: 20px 30px 20px 30px;
    border-radius: 10px;
    min-height: 715px;
    background-color: #fff;
    width: 90%;
    @media screen and (width < 1000px) {
        width:100%
    }
    &.grid-boxed{
        padding: 0px 180px 20px;
        @media only screen and (max-width: 1599px){
            padding: 0px 130px 20px;
        }
        @media only screen and (max-width: 1399px){
            padding: 0px 50px 20px;
        }
        @media only screen and (max-width: 991px){
            padding: 0px 30px 20px;
        }
    }

    .doughnutchart-inner{
        position: relative;
        .doughnutchart-inner-text{
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            width: 200px;
            line-height: 1;
            margin-bottom: 0px;
            display: inline-block;
            transform: translate(-50%, -50%);
            .doughnutchart-inner-content{
                font-size: 30px;
                font-weight: 600;
                line-height: 1;
                color: #000;
                display: block;
            }
            .doughnutchart-inner-label{
                font-size: 15px;
                color: gray;
            }
        }
        canvas{
            margin: 0 auto;
        }
    }
    .ant-switch{
        .anticon svg{
            color: #fff;
        }
    }
    /* ant breadcrumb */
    .ant-breadcrumb {
        a{
            color: gray;
        }
    }
    
    .page-header-actions{
        .ant-btn-white{
            color: gray !important;
            background-color: gray;
            &:hover{
                background-color: gray} !important;
            }
        }
    }
    /* Card Title */
    .ant-card{
        h1,
        h2,
        h3,
        h4,
        h5,
        h6{
            color: #000;
        }
    }
    .ant-card{
        &.ninjadash-ghost-card{
            .ant-card-body{
                background: linear-gradient(90deg, rgb(95, 99, 242), rgb(255, 105, 165));
            }
        }
    }
    
    .ant-card-head-title{
        color: #000;
    }
    .ant-card-bordered{
        background-color: #fff;
        border-color: #000;
    }
    .ant-card-head{
        border-bottom-color: #000;
        background-color: #fff;
        .ninjadash-card-title-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ninjadash-card-title-text{
                font-size: 18px;
                font-weight: 600;
                display: inline-block;
                margin-right: 0;
                color: #000;
                .ninjadash-card-subtitile{
                    font-size: 12px;
                    margin-right: 6px;
                    color: gray;
                }
            }
            .ninjadash-card-title-extra-text{
                display: flex;
                align-items: center;
                position: relative;
                top: 3px;
                .ninjadash-total-chart-total{
                    font-size: 18px;
                    font-weight: 600;
                    color: #000;
                    @media only screen and (max-width: 991px){
                        font-size: 15px;
                    }
                }
                .ninjadash-total-chart-status{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    margin-right: 8px;
                    @media only screen and (max-width: 991px){
                        font-size: 12px;
                    }
                    i,
                    svg{
                        width: 22px;
                        height: 22px;
                        margin-right: -1px;
                        margin-left: -1px;
                        @media only screen and (max-width: 991px){
                            width: 18px;
                            height: 18px;
                        }
                    }
                    &.ninjadash-total-chart-status-growth{
                        color: green;
                    }
                    &.ninjadash-total-chart-status-down{
                        color: red;
                    }
                }
            }
        }
    }

    .ant-card-body{
        p{
            color: gray;
        }
    }

    .ninjadash-card-nav{
        ul{
            display: flex;
            align-items: center;
            margin: 0 -10px 2px 0;
            margin: 0 0 2px -10px;
            li{
                margin: 5px 0;
                a{
                    font-size: 13px;
                    font-weight: 500;
                    display: inline-flex;
                    align-items: center;
                    border-radius: 6px;
                    min-height: 30px;
                    padding: 0 12px;
                    transition: .3s;
                    color: gray;
                    &:hover{
                        color: #8231D3;
                    }
                }
                &.ninjadash-active{
                    a{
                        background-color: #F2EAFB;
                        color: #8231D3;
                    }
                }
            }
        }
    }

    .ant-card-rtl .ant-card-extra{
        margin-left: 0 !important;
        
    }
    .ant-tabs-tab span svg {        
        padding-right: 5px;
    }
    .ant-btn{
        &.ant-btn-light{
            color: #fff;
            &.btn-transparent,
            &.btn-outlined{
                color: gray;
            }
            &.btn-outlined:hover{
                color: #8231D3;
            }
        }
    }
    /* Pagination Style */
    .ant-pagination-item{
        border-radius: 4px;
    }
    .ant-pagination-item a{
        font-weight: 400;
    }
    .ant-pagination-next svg{
        fill: gray;
    }

    .ant-pagination-options .ant-select-selector{
        padding: 0 6.5px
    }
    .ant-pagination-options .ant-select .ant-select-arrow{
        svg{
            width: 10px;
            fill: gray;
        }
    }
    
    /* Picker Under Input */
    .ant-form-item-control-input .ant-picker {
        padding: 0 12px 0 0 !important;
    }

    /* progressbars */

    .ant-progress {
        display: inline-flex !important;
        align-items: center;
        .ant-progress-inner{
            /* background-color#F8F9FBght']}; */
            background-color: transparent;
        }
        .ant-progress-text{
            color: gray;
        }
        .ant-progress-circle-trail{
            background-color: #282B37;
        }
    }

    .ant-progress>div {
        display: flex;
        flex-direction: column;
    }

    .ant-progress .ant-progress-outer {
        margin-left: 0 !important;
        padding-left: 0 !important;
    }

    .ant-progress .ant-progress-text {
        order: 0;
        align-self: flex-end;
        text-align: center;
    }

    .ant-progress-status-warning .ant-progress-bg {
        background: #fa8b0c;
    }

    /* progress bars */
    
    @media only screen and (max-width: 1199px){
        padding: 0px 15px;
    }
    @media only screen and (max-width: 991px){
        min-height: 580px;
    }
    .w-100{
        width: 100%;
    }
    .product-sidebar-col{
        @media only screen and (max-width: 767px){
            order: 2;
        }
    }
    .ant-skeleton-paragraph{
        margin-bottom: 0;
    }

    /* Button Group */
    .ant-btn-group{
        .ant-btn-default{
            border-color: #494B55;
            background-color: #282B37'; 
        }
    }

    /* ant skeleton */
    .ant-skeleton{
        &.ant-skeleton-active{
            .ant-skeleton-content {
                .ant-skeleton-title{
                    background: linear-gradient(90deg, #323541 25%, #494B55 37%, #323541 63%);
                    background-size: 400% 100%;
                }
                .ant-skeleton-paragraph{
                    >li{
                        background: linear-gradient(90deg, #323541 25%, #494B55 37%, #323541 63%);
                        background-size: 400% 100%;
                    }
                }
            }
            .ant-skeleton-avatar{
                background: linear-gradient(90deg, #323541 25%, #494B55 37%, #323541 63%);
                background-size: 400% 100%;
            }
        }
        .ant-skeleton-content {
            .ant-skeleton-title{
                background-color: #323541;
            }
            .ant-skeleton-paragraph{
                >li{
                    background-color: #323541;
                }
            }
        }
        .ant-skeleton-header{
            .ant-skeleton-avatar{
                background-color: #323541;
            }
        }
    }

    /* ant picker */
    .ant-picker{
        background-color: #282B37;
        border-color: #494B55;
        input{
            color: gray;
            &::placeholder{
                color: #747474;
            }
        }
        .ant-picker-suffix{
            svg{
                color: gray;
            }
        }
    }

    /* ant checkbox */
    .ant-checkbox{
        &:hover{
            .ant-checkbox-inner{
                border-radius: 4px;
            }
        }
        &.ant-checkbox-checked{
            &:after{
                border-color: #8231D3;
                border-radius: 4px;
            }
            .ant-checkbox-inner{
                background-color: #8231D3;
                border-color: #8231D3;
                &:after{
                    top: 44%;
                    border-color: #fff;
                }
            }
        }
        &.ant-checkbox-indeterminate{
            .ant-checkbox-inner{
                &:after{
                    background-color: #8231D3;
                }
            }
        }
    }
    .ant-checkbox-wrapper{
        &.ant-checkbox-wrapper-disabled{
            opacity: .5;
        }
        .ant-checkbox{
            &+span{
                color: gray;
            }
        }
    }
    .ant-checkbox-wrapper,
    .ant-checkbox{
        &:hover{
            .ant-checkbox-inner{
                border-color: #8231D3;
            }
        }
        .ant-checkbox-input{
            &:focus{
                &+.ant-checkbox-inner{
                    border-color: #8231D3;
                }
            }
        }
    }
    .ant-checkbox-inner{
        border-radius: 4px;
        background-color: #fff;
        border-color: #C6D0DC;
    }

    /* ant alert */
    .ant-alert-closable{
        .ant-alert-message{
          margin-right: 15px;
        }
    }

    .ant-alert-with-description .ant-alert-description{
        display: inline-block;
    }

    /* ant Calendar Picker */
    .ant-picker-calendar{
        .ant-badge-status-text{
            color: gray;
        }
    }
    .ant-picker-calendar-header .ant-picker-calendar-year-select{
        @media only screen and (max-width: 400px){
            width: 50% !important;
        }
    }
    .ant-picker-calendar-header .ant-picker-calendar-month-select{
        @media only screen and (max-width: 400px){
            width: calc(50% - 8px) !important
        }
    }

    /* Card Grid */
    .card-grid-wrap{
        .ant-card-grid{
            @media only screen and (max-width: 575px){
                width: 50% !important
            }
        }
    }

    /* Drawer */
    .atbd-drawer{
        .ant-card-body{
            text-align: center;
            .ant-drawer-wrapper-body {
                text-align: right;
            }
        }
    }
    .drawer-placement{
        @media only screen and (max-width: 400px){
            text-align: center;
        }
        .ant-radio-group{
            @media only screen and (max-width: 400px){
                margin-bottom: 15px;
            }
        }
    }
    .ant-drawer-content-wrapper{
        @media only screen and (max-width: 400px){
            width: 260px !important;
        }
        @media only screen and (max-width: 375px){
            width: 220px !important;
        }
    }

    /* Input */
    .input-wrap{
        @media only screen and (max-width: 991px){
            min-height: 500px;
        }
        input::placeholder{
            color: #4D4F5A;
        }
    }
    /* Modal Buttons */
    .modal-btns-wrap{
        margin: 0 -5px;
    }
    /* spinner */
    .ant-spin{
        margin-right: 20px;
        &:last-child{
            margin-right: 0;
        }
    }

    /* Column Cards Wrapper */
    .columnCardsWrapper{
        background: #EFF0F3;
        border-radius: 4px;
        padding: 50px 30px 25px;
    }
    .columnCardsWrapper .ant-card{
        background: #fff;
    }
    .columnCardsWrapper .ant-card-head{
        background: #fff;
    }
    .ant-card-grid{
        color: gray;
        box-shadow: 1px 0 0 0 #F1F2F6, 0 1px 0 0 #F1F2F6, 1px 0 0 0 #F1F2F6 inset, 0 1px 0 0 #F1F2F6 inset     
    }

    /* Ant Collapse */
    .ant-collapse{
        background-color: #fff;
        border-color: #F1F2F6;
        border-radius: 6px;
        > .ant-collapse-item{
            &:last-child{
                border-radius: 0 0 6px 6px;
                background-color: #fff;
                >.ant-collapse-header{
                    border-radius: 0 0 6px 6px;
                }
            }
            .ant-collapse-item{
                &:last-child{
                    background-color: transparent;
                }
                .ant-collapse-header{
                    border-radius: 6px;
                }
            }
            .ant-collapse-content{
                background-color: #fff;
                .ant-collapse-content-box{
                    background-color: #fff;
                }
            }
        }
        .ant-collapse-header{
            .ant-collapse-header-text{
                color: #E1E1E3;
            }
        }
    }
    .ant-collapse.ant-collapse-icon-position-left .ant-collapse-header{
        color: gray;
        padding: 12px 16px 10px 45px;
        background-color: #fff;
        @media only screen and (max-width: 1299px){
            padding: 12px 16px 10px 30px;
        }
    }
    .ant-collapse-content p{
        color: #747474;
        margin-bottom: 0;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 20px 20px 20px;
    }
    .ant-collapse-content > .ant-collapse-content-box .ant-collapse-content-box{
        padding: 10.5px 20px;
    }
    .ant-collapse.ant-collapse-borderless{
        background-color: #fff;
        > .ant-collapse-item{
            &:last-child{
                border-radius: 0px;
            }
        }
    }
    .ant-collapse > .ant-collapse-item,
    .ant-collapse .ant-collapse-content{
        border-color: #323541;
    }
    .ant-collapse > .ant-collapse-item.ant-collapse-item-disabled .ant-collapse-header{
        color: #A0A0A0 !important;
    }

    .ant-collapse > .ant-collapse-item .ant-collapse-header .ant-collapse-arrow{
        font-size: 8px;
        position: relative;
        top: 0;
        right: 0;
    }

    .ant-collapse .ant-collapse {
        border: 0 none;
        background: #fff;
    }

    .ant-collapse .ant-collapse > .ant-collapse-item {
        border-bottom: 0;
    }
    .ant-collapse .ant-collapse .ant-collapse-header{
        border-radius: 6px 6px 0 0;
        border: 1px solid #323541;
        background: #F8F9FB;
    }
    .ant-collapse .ant-collapse .ant-collapse-content{
        margin: 20px 0 0 0;
        border: 1px solid #323541;
        border-radius: 0;
    }

    /* Ant Radio */
    .ant-radio-wrapper{
        .ant-radio{
            &+span{
                color: gray;
            }
        }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
        box-shadow: 0 0;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        background-color: #ffffff;
    }
    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
        border-right-color: #ffffff;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
        border-color: #ffffff;
    }
    .ant-radio-button-wrapper{
        height: 48px;
        line-height: 46px;
        padding: 0 25.25px;
        color: gray;
        background-color: #282B37;
        border-color: #F1F2F6;
        @media only screen and (max-width: 1024px){
            padding: 0 10px;
        }
        @media only screen and (max-width: 379px){
            height: 40px !important;
            line-height: 38px !important;
            font-size: 12px;
            padding: 0 6px;
        }
        &:not(:first-child){
            &:before{
                background-color: #F1F2F6;
            }
        }
        &.ant-radio-button-wrapper-checked{
            color: #fff;
        }
    }

    /* Select */
    .ant-tree-select .ant-select-selector{
        height: 42px;
    }
    .ant-select:not(.ant-select-customize-input) {
        .ant-select-selector{
            background-color: #282B37;
            border-color: #E3E6EF;
        }
    }
    .tag-select-list{
        margin-bottom: -10px;
        .ant-select{
            margin-bottom: 10px;
        }
    }
    .ant-select-selector{
        border-color: #E3E6EF !important;
    }

    .ant-select{
        .ant-select-selector {
            .ant-select-selection-item{
                color: gray;
            }
        }
        &.ant-select-disabled{
            opacity: .6;
        }
        &.ant-select-multiple{
            .ant-select-selection-item{
                height: 24px;
                align-items: center;
                color: gray;
                padding-right: 8px;
                background-color: #fff;
                border-color: #E3E6EF;
            }
        }
        
        &.ant-select-lg{
            height: 50px;
            line-height: 48px;
            .ant-select-selector{
                height: 50px !important;
                line-height: 48px;
            }
            .ant-select-selection-item{
                line-height: 48px !important;
                padding-right: 8px;
            }
            &.ant-select-multiple.ant-select-lg .ant-select-selection-item{
                height: 32px;
                line-height: 32px !important;
            }
        }
        &.ant-select-multiple.ant-select-sm{
            .ant-select-selection-item{
                height: 16px;
                line-height: 14px;
                font-size: 11px;
            }
        }
        .ant-select-arrow{
            svg{
                color: gray;
            }
        }
    }

    /* Slider */
    .slider-with-input{
        .ant-slider{
            margin-right: 15px;
        }
        .slider-with-input__single{
            margin-bottom: 15px;
        }
        .ant-input-number{
            background-color: #282B37;
            border-color: #F1F2F6;
        }
        .ant-input-number-input{
            background-color: #282B37;
            border-color: #F1F2F6;
        }
    }

    .ant-slider-mark-text{
        color: gray;
    }

    .anticon svg{
        color: #A4A5AA;
    }

    .ant-slider-handle{
        background-color: #282B37
    }

    /* ant input */
    .ant-input,
    .ant-input-affix-wrapper{
        color: gray;
        background-color: #282B37;
        border-color: #E3E6EF;
        &:focus{
            border-color: #E3E6EF;
            box-shadow: 0 0;
        }
        input.ant-input{
            &:focus{
                border-color: #E3E6EF;
                box-shadow: 0 0 !important;
            }
        }
    }

    .ant-input-affix-wrapper{
        &:not(.ant-input-affix-wrapper-disabled){
            &:hover{
                border-color: #E3E6EF;
            }
        }
    }
    .ant-input-affix-wrapper-focused{
        box-shadow: 0 0;
    }

    /* Taglist */
    .ant-tag{
        margin: 5px;
    }
    .taglist-wrap{
        margin: -5px;
        .ant-tag {
            line-height: 22px;
            padding: 0 10.2px;
            border: 0 none;
            margin: 5px;
            color: gray;
            &.ant-tag-has-color{
                color: #fff !important;
            }
            &.ant-tag-magenta{
                color: #eb2f96;
            }
            &.ant-tag-red{
                color: #f5222d;
            }
            &.ant-tag-volcano{
                color: #fa541c;
            }
            &.ant-tag-orange{
                color: #fa8c16;
            }
            &.ant-tag-gold{
                color: #faad14;
            }
            &.ant-tag-line{
                color: #a0d911;
            }
            &.ant-tag-green{
                color: #a0d911;
            }
            &.ant-tag-cyan{
                color: #13c2c2;
            }
            &.ant-tag-blue{
                color: #1890ff;
            }
            &.ant-tag-geekbule{
                color: #2f54eb;
            }
            &.ant-tag-purple{
                color: #722ed1;
            }
        }
    }

    .ant-tag {
        color: #0A0A0A;
        background-color: #282B37;
        border-color: #E3E6EF;
        a{
            color: #0A0A0A;
        }
        svg{
            color: gray;
        }
    }

    /* ant empty */

    .ant-empty-description{
        color: gray;
    }

    /* Steps style */
    .ant-steps-item-finish,
    .ant-steps-item-wait {
        .ant-steps-item-icon{
            background-color: #fff;
        }
        > .ant-steps-item-container {
            > .ant-steps-item-content {
                > .ant-steps-item-title{
                    color: gray;
                }
            }
        }
    }

    .steps-content{
        color: gray;
    }

    .ant-steps-item-wait {
        .ant-steps-item-icon{
            border-color: #323541;
            .ant-steps-icon{
                color: gray;
            }
        }
    }

    .ant-steps-item-process{
        > .ant-steps-item-container {
            > .ant-steps-item-content {
                > .ant-steps-item-title{
                    color: #0A0A0A;
                }
            }
        }
    }

    /* Timepicker List */
    .timepicker-list{
        margin: -5px;
        .ant-picker{
            margin: 5px;
        }
    }

    /* ant statistics */

    .ant-statistic {
        .ant-statistic-title{
            color: #A4A5AA;
        }
        .ant-statistic-content{
            color: #0A0A0A;
        }
    }
    .ant-statistic-content{
        .anticon {
            &.anticon-arrow-up{
                svg{
                    color: #01B81A;
                }
            }
            &.anticon-arrow-down{
                svg{
                    color: #FF0F0F;
                }
            }
        }
    }

    /* Ant Menu */
    .ant-menu{
        background-color: #1B1E2B;
        border-color: #323541;
        .ant-menu-submenu-title{
            svg,
            .ant-menu-title-content,
            .ant-menu-submenu-arrow{
                color: #A4A5AA;
            }
            &:active{
                background-color#ffffff']};
            }
        }
        .ant-menu-item{
            &:active{
                background-color#ffffff']};
            }
            &.ant-menu-item-selected{
                &:after{
                    border-color: #ffffff;
                }
                .ant-menu-item-icon{
                    svg{
                        color: #ffffff;
                    }
                }
                .ant-menu-title-content{
                    color: #ffffff;
                }
            }
            &.ant-menu-item-disabled{
                .ant-menu-item-icon{
                    color: #A0A0A0;
                }
                .ant-menu-title-content{
                    opacity: .5;
                    color: #A0A0A0;
                }
            }
            &.ant-menu-item-active{
                color: #ffffff;
            }
        }
        .ant-menu-submenu {
            &.ant-menu-submenu-selected{
                .ant-menu-submenu-title{
                    span{
                        color: #ffffff;
                    }
                }
            }
        }
        &.ant-menu-inline{
            .ant-menu-submenu-title svg{
                position: relative;
                top: 2px;
            }
            .ant-menu-submenu #ffffff                   .anticon {
                        svg{
                            color: #ffffff;
                        }
                    }
                    svg{
                        color: #ffffff;
                    }
                    .ant-menu-submenu-arrow{
                        color: #ffffff;
                    }
                }
            }
        }
        &:not(.ant-menu-horizontal){
            .ant-menu-item-selected,
            .ant-menu-submenu-open{
                background-color#ffffff']};
                &.ant-menu-item-active{
                    color: #ffffff;
                }
            }
        }
    }

    .ant-menu-horizontal{
        &:not(.ant-menu-dark){
            > .ant-menu-submenu{
                &:hover{
                    .ant-menu-submenu-title{
                        svg,
                        .ant-menu-title-content{
                            color: #ffffff;
                        }
                    }
                    &:after{
                        border-bottom-color: #ffffff;
                    }
                }
            }
        }
    }

    /* ant result */
    .ant-result{
        &.ant-result-success{
            .anticon svg{
                color: #01B81A;
            }
        }
        &.ant-result-info{
            .anticon svg{
                color: #00AAFF;
            }
        }
        &.ant-result-warning{
            .anticon svg{
                color: #FA8B0C;
            }
        }
        &.ant-result-error{
            .anticon svg{
                color: #FF0F0F;
            }
        }
        .ant-result-title{
            color: #0A0A0A;
        }
        .ant-result-subtitle{
            font-size: 13px;
            color: gray;
        }
        .ant-result-content{
            background-color#F8F9FBght']};
            .ant-typography{
                color: gray;
                a{
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }
        }
    }

    .ant-result-extra{
        .ant-btn-default{
            color: gray;
            border-color: #E3E6EF;
            background-color#F8F9FBght']};
        }
    }

    /* Ant Comment */
    .ant-comment-inner{
        padding: 0;
        .ant-comment-content-author,
        .ant-comment-content-author-name{
            >*{
                color: gray;
            }
        }
    }
    .ant-comment-content-detail p{
        color: #A4A5AA;
    }
    .ant-list-items{
        padding-top: 22px;
    }
    .ant-list-items li:not(:last-child){
        margin-bottom: 22px;
    }
    .ant-comment:not(:last-child){
        margin-bottom: 22px;
    }
    .ant-comment-nested{
        margin-top: 22px;
    }
    .ant-comment-actions li span{
        color: #A0A0A0;
    }
    .ant-comment-content-detail textarea{
        resize: none;
        min-height: 170px;
        border-radius: 5px;
        background-color: #282B37;
        border-color: #E3E6EF;
    }
    .nested-comment-wrapper  {
        .comment-title{
            font-size: 12px;
            padding-bottom: 10px;
            margin-bottom: 22px;
            border-bottom: 1px solid #E3E6EF;
        }
        
    }

    .ant-list-split .ant-list-header{
        color: gray;
        border-color: #323541;
    }

    /* ant list */
    .ant-list{
        &.ant-list-bordered{
            color: gray;
            border-color: #E3E6EF;
        }
        .ant-list-item-meta{
            padding: 10px 24px; 
            border-color: #323541;
        }
        .ant-list-item-meta-description{
            color: gray;
        }
    }

    /* Vector Map */
    .rsm_map{
        min-height: 505px;
        .world-map{
            width: 100%;
            height: auto;
            @media only screen and (max-width: 1599px){
                height: 480px;
            }
            @media only screen and (max-width: 1399px){
                height: 400px;
            }
            @media only screen and (max-width: 575px){
                height: 400px;
            }
            @media only screen and (max-width: 767px){
                height: 300px;
            }
            @media only screen and (max-width: 575px){
                height: 250px;
            }
            @media only screen and (max-width: 479px){
                height: 350px;
            }
            @media only screen and (max-width: 375px){
                height: 240px;
            }
        }
        .controls{
            position: absolute;
            right: 30px;
            bottom: 30px;
            button{
                display: block;
                width: 27px;
                height: 27px;
                background: none;
                color: #ffffff;
                border: 1px solid #323541;
                padding: 0;
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #1B1E2B;
                cursor: pointer;
                &:first-child{
                    border-radius: 6px 6px 0 0;
                }
                &:last-child{
                    border-radius: 0 0 6px 6px;
                }
                &:focus{
                    outline: none;
                }
                svg{
                    width: 10px;
                    color: gray;
                }
            }
            button + button{
                border-top: 0 none;
            }
        }
    }

    /* Checkout Wrapper */
    .checkoutWraper{
        .ant-card-body{
            padding: 50px 50px 50px 30px !important;
            @media only screen and (max-width: 575px){
                padding: 15px !important;
            }
            .ant-card-body{
                padding: 25px !important;
                @media only screen and (max-width: 575px){
                    padding: 15px !important;
                }
            }
        }
        .ant-steps{
            margin-top: 0;
        }
        .step-action-wrap{
            @media only screen and (max-width: 1599px){
                margin-bottom: 50px;
            }
            @media only screen and (max-width: 767px){
                margin-bottom: 30px;
            }
        }
    }

    /* Star Active */
    a{
        i,
        span.fa{
          font-size: 16px;
          color: #A0A0A0;
        }
        &.starDeactivate{
          i:before{
            content: "\f31b";
          }
        }
        &.starActive{
          i,
          span.fa{
            color: #FA8B0C;
          }
          i:before,
          span.fa:before{
            color: #FA8B0C;
            content: "\f005";
    
          }
        }
    }

    .ant-timeline{
        color: gray;
        .ant-timeline-item-content{
            font-size: 16px;
        }
    }

    .ant-timeline-item-tail{
        border-width: 3px;
        border-color: #323541;
    }

    
    .ant-rate-content{
        font-weight: 500;
        color: gray;
    }

    .ant-rate-text{
        color: gray;
    }

    .ant-rate{
        .anticon {
            svg{
                color: #FA8B0C;
            }
        }
    }

    .ant-rate-star.ant-rate-star-zero span svg{
        color: #c6d0dc;
    }

    .account-card{
        .ant-card-head{
            .ant-card-extra{
                @media only screen and (max-width: 575px){
                   padding-top: 0 !important;
                }
            }
            
        }
                
    }

    /* Rechart */
    .recharts-default-legend{
        .recharts-legend-item{
            min-width: 100px !important;
        }
    }

    /*  Radio */
    .ant-radio{
        &.ant-radio-disabled{
            opacity: .4;
            &+span{
                opacity: .4;
            }
        }
    }
    .radio-size-wrap{
            .ant-radio-button-wrapper{
                @media only screen and (max-width: 1450px){
                    padding: 0 11.5px;
            }
        }
    }
    .ant-radio-wrapper:hover .ant-radio, 
    .ant-radio:hover .ant-radio-inner, 
    .ant-radio-input:focus + .ant-radio-inner{
        border-color: #8231D3;
        outline: none;
        box-shadow: 0 0;
    }

    .ant-radio-inner{
        transition: 0s;
        background-color: #282B37;
        border-color: #E3E6EF;
    }
    .ant-radio-checked{
        .ant-radio-inner{
            border-color: #8231D3;
        }
    }

    .ant-radio-button-wrapper{
        &.ant-radio-button-wrapper-disabled{
            opacity: .4;
        }
    }

    .ant-radio-button-wrapper{
        &.ant-radio-button-wrapper-checked{
            &.ant-radio-button-wrapper-disabled{
                background-color: #ffffff;
            }
        }
    }
    

    /* Message  */
    .message-button-list{
        margin: -4px;
        .ant-btn {
            margin: 4px;
        }
    }
    /* Chart Label */

    .chart-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
        color: #5a5f7d;
    }

    .chart-label .label-dot {
        margin-right: 8px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
    }

    .chart-label .label-dot.dot-success {
        background: #20c997;
    }

    .chart-label .label-dot.dot-info {
        background: #5f63f2;
    }

    .chart-label .label-dot.dot-warning {
        background: #fa8b0c;
    }

    .chart-label .label-dot {
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 10px;
    }

    // Ant comment action
    .ant-comment-actions{
        li{
            position: relative;
            &:not(:last-child){
                margin-left: 8px;
                padding-left: 8px;
                &:after{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 12px;
                    background-color: #C6D0DC;
                    content: '';
                }
            }
            .com-time{
                cursor: default;
            }
            span{
                margin-left: 0;
            }
        }
    }

    // Emoji Picker React
    .emoji-picker-react{
        top: 15px;
        right: 25px;
        box-shadow: 0 5px 10px #efefef10;
        @media only screen and (max-width: 479px){
            top: 25px;
            right: -50px;
            width: 260px;
        }
        .emoji-categories{
            padding: 0 10px;
        }
        .emoji-search{
            margin: 0 10px;
        }
        .content-wrapper:before{
            display: none;
        }
        .emoji-group{
            padding: 0 10px;
        }
        .emoji-group:before{
            font-size: 12px;
            font-weight: 600;
            color: #0A0A0A;
        }
        .emoji-group .emoji-img{
            margin: 5px !important;
        }
    }

    .wizard-side-border{
        >.ant-card{
            .ant-card-body{
                padding: 0 25px !important;
            }
        }
        .checkout-successful{
            >.ant-card{
                .ant-card-body{
                    padding: 25px !important;
                    @media only screen and (max-width: 575px){
                        padding: 15px !important;
                    }
                }
            }
        }
        .payment-method-form.theme-light{
            .shipping-selection__card{
                .ant-card-body{
                    padding: 25px 0 !important;
                }
            }
        }
        .shipping-selection__card{
            .ant-card-body{
                padding: 25px !important;
                @media only screen and (max-width: 575px){
                    padding: 15px !important;
                }
            }
        }
        .atbd-review-order{
            .ant-card-body{
                padding: 25px 25px 0 !important;
                @media only screen and (max-width: 767px) {
                    padding: 15px 15px 0 !important;
                }
            }
        }
        
        .ant-steps {
            padding: 50px;
            @media only screen and (max-width: 1399px) {
                padding: 25px;
            }
        }
        .steps-wrapper{
            padding: 50px;
            @media only screen and (max-width: 1399px) {
                padding: 25px;
            }
            border-left: 1px solid $#323541;
        }
    }
    .editor-compose > div {
        position: static;
        max-width: 100%;
        margin: 0 0 25px;
    }

    // Ant Dragger
    .ant-upload-drag{
        background-color: #1B1E2B !important;
        border-radius: 10px !important;
        display: flex;
        align-items: center;
        min-height: 100px;
        border-color: #C6D0DC;
        &.ninjadash-uploader-large{
            min-height: 180px;
        }
        .ant-upload-drag-container{
            .ant-upload-text{
                margin-bottom: 0;
                font-size: 15px;
                color: #9299B8;
            }
        }
    }

    .ant-upload{
        .ant-btn-light{
            color: gray;
            &:hover{
                border-color: #ffffff;
            }
            svg{
                color: gray;
            }
        }
    }

    .ant-upload-list-item{
        .ant-upload-list-item-info{
            border-radius: 6px;
            padding: 0px 8px;
            background-color: transparent;
            svg{
                color: gray;
            }
            a{
                color: #ffffff;
            }
        }
    }

    // Form Validation
    .ant-form-item{
        &.ant-form-item-has-success{
            .ant-input{
                border-color: #01B81A;
            }
            &.ant-form-item-with-help{
                .ant-form-item-explain{
                    color: #01B81A;
                }
            }
        }
        &.ant-form-item-with-help{
            .ant-form-item-explain{
                margin-top: 6px;
            }
        }
    }
    /* Order Summery */
    .ninjadash-order-summery{
        background-color#F8F9FBght']};
    }

    .ant-table{
        background-color: #1B1E2B;
    }

    .ant-table-thead {
        > tr {
            > th{
                background-color#F8F9FBght']};
                border-color: #323541;
                color: #0A0A0A;
            }
        }
    }
    .ant-table-tbody{
        >tr{
            >td{
                border-color: #323541;
                background-color: #1B1E2B;
                color: gray;
            }
            &:hover{
                >td{
                    background-color#F8F9FBght']};
                }
            }
        }
    }
    .ant-btn-transparent,
    .btn-outlined,
    .ant-btn-white
    {
        .anticon-loading{
            svg{
                color: #8231D3;
            }
        } 
    }
    .anticon-loading{
        svg{
            color: #fff;
        }
    }

    .ninjadash-action-count{
        padding-right: 8px;
    }
`;

const CalendarWrapper = Styled.div`
    &.ninjadash-calendar-with-head{
      .react-calendar{
        padding: 0;
        margin-bottom: 0;
      }
    }
    &.ninjadash-calendar-wrap{
      .react-calendar{
        padding: 30px 25px 20px;
      }
      .react-calendar__month-view__days .react-calendar__tile{
        height: 46px !important;
        @media only screen and (max-width: 767px){
          height: 42px !important;
        }
      }
      .ninjadasgcaled {
        @media only screen and (max-width: 1199px){
          min-height: 750px;
        }
      }
    }
    .react-calendar{
        font-family: 'Jost', sans-serif;
        width: 100%;
        border-radius: 10px;
        padding: 60px 55px 40px;
        border: 0 none;
        background-color: #1B1E2B;
        margin-bottom: 25px;
        box-shadow: 0 5px 20px #4D4F5A03;
        @media only screen and (max-width: 1599px){
            padding: 40px 35px 50px;
        }
        @media only screen and (max-width: 575px){
            padding: 15px;
        }
        .react-calendar__viewContainer{
            .react-calendar__year-view__months__month {
                &:hover{
                    background-color: transparent;
                }
                &.react-calendar__tile--hasActive{
                    background-color: transparent;
                    color: #8231D3
                }
            }
        }
    }
    .react-calendar__navigation{
        justify-content: center;
        margin-bottom: 22px;
        height: auto;
        button{
            &:hover,
            &:focus{
                background-color: #fff !important;
            }
        }
        .react-calendar__navigation__arrow{
            font-size: 20px;
            min-width: auto;
            color: color: gray;
        }
        .react-calendar__navigation__label{
            flex-grow: 0 !important;
            font-size: 16px;
            font-weight: 500;
            margin: 0 15px;
            color: #0A0A0A;
            &:hover{
              background-color: transparent
            }
        }
    }
    .react-calendar__month-view__weekdays{
        .react-calendar__month-view__weekdays__weekday{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .8rem .5rem;
            abbr{
                font-size: 13px;
                font-weight: 500;
                text-decoration: none;
                color: #A4A5AA;
                @media only screen and (max-width: 1300px){
                    font-size: 11px;
                }
            }
        }
    }
    .react-calendar__month-view__days{
        .react-calendar__tile{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 64px !important;
            padding: 1em 0.5em;
            @media only screen and (max-width: 1599px){
              height: auto !important;
            }
            &:hover{
                background-color: transparent;
                font-weight: 500;
                color: color: #0A0A0A;
            }
            &.react-calendar__month-view__days__day--neighboringMonth{
                abbr{
                    color: #A0A0A0;
                }
            }
            abbr{
                font-size: 12px;
                color: gray;
            }
            &.react-calendar__tile--now{
                padding: 0.929em 0.5em;
                background-color: transparent;
                border-radius: 6px;
                border: 1px solid #8231D3;
            }
            &.react-calendar__tile--active{
                border-radius: 6px;
                border-color: #ffffff;
                background-color: #ffffff;
                abbr{
                    color: #ffffff;
                }
            }
        }
    }
    .calendar-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        @media only screen and (max-width: 1599px){
            flex-flow: column;
        }
        .calendar-header__left{
            display: flex;
            align-items: center;
            position: relative;
            @media only screen and (max-width: 1599px){
                margin-bottom: 20px;
            }
            @media only screen and (max-width: 479px){
                flex-flow: column;
            }
            .btn-today{
                font-size: 14px;
                font-weight: 500;
                height: 34px;
                padding: 0 15px;
                border-radius: 4px;
                text-transform: capitalize;
                cursor: pointer;
                border: 1px solid #323541;
                @media only screen and (max-width: 479px){
                    margin-bottom: 15px;
                }
            }
            .react-calendar{
                margin-left: 30px;
                padding: 0;
                border: none;
                .react-calendar__navigation{
                    align-items: center;
                    margin: 0;
                    border: none;
                    .react-calendar__navigation__label{
                        padding: 0 20px;
                    }
                    .react-calendar__navigation__arrow {
                        border: 1px solid #323541;
                        border-radius: 5px;
                        width: 34px;
                        height: 34px;
                        svg,
                        img{
                            width: 14px;
                        }
                    }
                }
                .react-calendar__viewContainer{
                    position: absolute;
                    background: #1B1E2B;
                    z-index: 999;
                    border: 1px solid #ddd;
                    display: none;
                    &.show{
                        display: block;
                    }
                }
            }
            .calender-head__navigation{
                display: flex;
                align-items: center;
                margin-left: 30px;
                @media only screen and (max-width: 479px){
                    margin-left: 0px;
                }
                .btn-navigate{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 34px;
                    height: 34px;
                    padding: 0;
                    cursor: pointer;
                    i,
                    svg{
                        color: #4D4F5A;
                    }
                }
                .date-label{
                    display: inline-block;
                    margin: 0 20px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #0A0A0A;
                    @media only screen and (max-width: 575px){
                      font-size: 12px;
                    }
                }
            }
        }
        .calendar-header__right{
            display: flex;
            @media only screen and (max-width: 479px){
                flex-flow: column;
                align-items: center;
            }
            svg{
                width: 14px;
            }
            ul{
                margin-bottom: 0;
                li{
                    display: inline-block;
                    &:first-child{
                        border-left: 1px solid #323541;
                    }
                    &:not(:first-child){
                        a{
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                        }
                    }
                    &:not(:last-child){
                        a{
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                        }
                    }
                    a{
                        font-size: 13px;
                        font-weight: 500;
                        color: #A4A5AA;
                        border: 1px solid #323541;
                        border-left: 0px;
                        display: block;
                        border-radius: 4px;
                        padding: 6px 13.24px;
                        &.active{
                            color: #fff;
                            border-color: #8231D3;
                            background-color: #8231D3;
                        }
                    }
                }
            }
            .schedule-list{
                margin-left: 20px;
                display: flex;
                align-items: center;
                color: #A4A5AA;
                @media only screen and (max-width: 479px){
                    margin: 15px 0 0 0;
                }
                svg,
                img,
                i{
                    margin-right: 6px;
                    color: #8231D3;
                }
                &.active{
                    color: #8231D3;
                }
            }
        }
    }

    .table-event{
        
        border: 1px solid #323541;
        &.table-responsive{
            @media only screen and (max-width: 1599px){
                display: block;
                width: 100%;
                overflow-x: auto;
            }
        }
        tr{
            th,
            td{
                padding: 16px 0;
                border-bottom: 1px solid #323541;
                &:first-child{
                    min-width: 75px;
                    padding: 16px 18px 16px 18px;
                    border-right: 1px solid color: $#323541;
                }
            }
        }
        thead{
            th{
                padding: 0 22px;
                background-color: #F8F9FB;
                p{
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 0;
                }
            }
        }
        tbody{
            td{
                padding: 0 10px;
                font-size: 12px;
                min-width: 140px;
                color: #4D4F5A;
                .currentTime{
                    width: calc(100% + 20px);
                    height: 1px;
                    display: block;
                    position: relative;
                    left: -10px;
                    z-index: 222;
                    &:after{
                        position: absolute;
                        left: 0;
                        left: 0px;
                        top: -6px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        content: '';
                        background-color: #5840FF;
                    }
                }
                .day-event-item{
                    width: 100%;
                    display: block;
                    border-radius: 3px 6px 6px 3px;
                    padding: 6px 12px;
                    margin: 2px 0;
                    position: relative;
                    &:after{
                        position: absolute;
                        left: 0;
                        left: 0px;
                        bottom: 0;
                        width: 2px;
                        height: 100%;
                        border-radius: 6px 0 0 6px;
                        content: '';
                    }
                    &.primary{
                        background-color: #EFEFFE;
                        color: #8231D3;
                        &:hover{
                            color: #8231D3;
                        }
                        &:after{
                            background-color: #8231D3;
                        }
                    }
                    &.secondary{
                        background-color: #FFF0F6;
                        color: #5840FF;
                        &:hover{
                            color: #5840FF;
                        }
                        &:after{
                            background-color: #5840FF;
                        }
                    }
                    &.success{
                        background-color: #E8FAF4;
                        color: #01B81A;
                        &:after{
                            background-color: #01B81A;
                        }
                    }
                    &.warning{
                        background-color: #FFF3E6;
                        color: #FA8B0C;
                        &:after{
                            background-color: #FA8B0C;
                        }
                    }
                    &.info{
                        background-color: #00AAFF10;
                        color: #00AAFF;
                        &:after{
                            background-color: #00AAFF;
                        }
                    }
                    .event-title{
                        display: block;
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 4px;
                    }
                }
            }
            
        }
    }

    .emptyData{
        min-height: 110px;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        color: #4D4F5A;
    }

    .event-week{
        tr{
            th,
            td{
                &:not(:last-child){
                    border-right: 1px solid color: $#323541;
                }
            }
        }
        thead{
            tr{
                th{
                    text-align: center;
                    .week-dayName{
                        font-size: 13px;
                        font-weight: 400;
                        margin-bottom: 2px;
                        color: #4D4F5A;
                    }
                    .week-date{
                        display: inline-block;
                        line-height: 1.45;
                        border-radius: 9px;
                        font-size: 13px;
                        &.primary{
                            padding: 0 10px;
                        }
                    }
                }
            }
        }
        tbody{
            .ant-dropdown-trigger{
                display: inline-block;
            }
        }
    }

    .schedule-event{
        tr{
            &:hover{
                box-shadow: 0 15px 40px #4D4F5A15;
                td{
                    border-color: #fff;
                }
            }
            td{
                padding: 10px 20px !important;
                vertical-align: top;
                font-size: 14px;
                &:first-child{
                    border-right: 0 none;
                }
                &.schedule-time{
                    direction: ltr;
                    min-width: 100px;
                    width: 130px;
                }
                .ant-row{
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                }
                .schedule-date{
                    font-weight: 500;
                    color: #0A0A0A;
                }
                .schedule-date-name{
                    margin-left: 10px;
                }
                .event-title{
                    font-weight: 500;
                    color: #0A0A0A;
                    @media only screen and (max-width: 575px){
                        display: inline-block;
                        margin-top: 6px; 
                    }
                }
                .schedule-time{
                    font-size: 13px;
                    color: #4D4F5A;
                }
            }
        }
    }

    .events{
        li{
            position: relative;
            height: 35px;
            a{
                position: absolute;
                top: 0;
                left: 0;
                left: 0;
                padding: 5px 12px;
                z-index: 1;
                border-radius: 4px;
                font-size: 13px;
                &:hover{
                    color: #fff;
                }
            }
        }
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        overflow-y: unset;
    }  
    .rbc-btn {
        color: inherit;
        font: inherit;
        margin: 0;
      }
      button.rbc-btn {
        overflow: visible;
        text-transform: none;
        -webkit-appearance: button;
        cursor: pointer;
      }
      button[disabled].rbc-btn {
        cursor: not-allowed;
      }
      button.rbc-input::-moz-focus-inner {
        border: 0;
        padding: 0;
      }
      .rbc-calendar {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 10px;
        @media only screen and (max-width: 575px){
          padding: 10px;
        }
      }
      .rbc-calendar *,
      .rbc-calendar *:before,
      .rbc-calendar *:after {
        box-sizing: inherit;
      }
      .rbc-abs-full,
      .rbc-row-bg {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .rbc-ellipsis,
      .rbc-event-label,
      .rbc-row-segment .rbc-event-content,
      .rbc-show-more {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .rbc-rtl {
        direction: rtl;
      }
      .rbc-header > a,
      .rbc-header > a:active,
      .rbc-header > a:visited {
        color: inherit;
        text-decoration: none;
        display: inline-block;
        padding-bottom: 7px;
      }
      .rbc-row-content {
        position: relative;
        user-select: none;
        z-index: 4;
        .rbc-row{
          &+.rbc-row{
            margin-top: 6px;
          }
        }
      }

      .rbc-event {
        border: none;
        box-shadow: none;
        margin: 0;
        padding: 3px 15px;
        color: #fff;
        cursor: pointer;
        width: 100%;
        text-align: left;
        border-radius: 4px;
        @media only screen and (max-width: 575px){
          font-size: 12px;
        }
        &.primary{
          background-color: #8231D3;
        }
        &.secondary{
          background-color: #5840FF;
        }
        &.info{
          background-color: #00AAFF;
        }
        &.warning{
          background-color: #FA8B0C;
        }
        &.success{
          background-color: #01B81A;
        }
      }
      .rbc-slot-selecting .rbc-event {
        cursor: inherit;
        pointer-events: none;
      }
      .rbc-event-label {
        font-size: 80%;
      }
      .rbc-event-overlaps {
        box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
      }
      .rbc-event-continues-prior {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .rbc-event-continues-after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .rbc-event-continues-earlier {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .rbc-event-continues-later {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .rbc-row {
        display: flex;
        flex-direction: row;
      }
      .rbc-row-segment {
        padding: 0 1px 1px 1px;
      }
      .rbc-selected-cell {
        background-color: rgba(0, 0, 0, 0.1);
      }
      .rbc-show-more {
        background-color: rgba(255, 255, 255, 0.3);
        z-index: 4;
        font-weight: bold;
        font-size: 85%;
        height: auto;
        line-height: normal;
        white-space: nowrap;
      }
      .rbc-month-view {
        position: relative;
        border: 1px solid #323541;
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        width: 100%;
        user-select: none;
        height: 100%;
        background-color: #1B1E2B;
        border-radius: 10px;
      }
      .rbc-month-row{
        &:not(:last-child){
          .rbc-day-bg {
            border-bottom: 1px solid #323541;
          }
        }
      }
      .rbc-date-cell{
        &.rbc-off-range{
          .rbc-button-link{
            color: #A0A0A0;
          }
        }
        .rbc-button-link{
          margin-top: 5px;
          color: gray;
          @media only screen and (max-width: 575px){
            font-size: 12px;
          }
        }
      }
      .rbc-month-header {
        display: flex;
        flex-direction: row;
      }
      .rbc-month-row {
        display: flex;
        position: relative;
        flex-direction: column;
        flex: 1 0 0;
        flex-basis: 0px;
        overflow: hidden;
        height: 100%;
      }
      .rbc-month-row + .rbc-month-row {
      }
      .rbc-date-cell {
        flex: 1 1 0;
        min-width: 0;
        padding-right: 5px;
        text-align: right;
      }
      .rbc-date-cell.rbc-now {
        font-weight: bold;
      }
      .rbc-date-cell > a,
      .rbc-date-cell > a:active,
      .rbc-date-cell > a:visited {
        color: inherit;
        text-decoration: none;
      }
      .rbc-row-bg {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        overflow: hidden;
      }
      .rbc-day-bg {
        flex: 1 0 0%;
        &:not(:last-child){
          border-right: 1px solid #323541;
        }
        &.rbc-today{
          position: relative;
          background-color: #8231D315;
          &:after{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 2px;
            content: '';
            background-color: #8231D3;
          }
        }
      }
      .rbc-day-bg + .rbc-day-bg {
      }
      .rbc-rtl .rbc-day-bg + .rbc-day-bg {
        border-left-width: 0;
      }
      .rbc-overlay {
        position: absolute;
        z-index: 5;
        border: 1px solid #e5e5e5;
        background-color: #fff;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
        padding: 10px;
      }
      .rbc-overlay > * + * {
        margin-top: 1px;
      }
      .rbc-overlay-header {
        border-bottom: 1px solid #e5e5e5;
        margin: -10px -10px 5px -10px;
        padding: 10px;
      }
      .rbc-agenda-view {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        overflow: auto;
        .rbc-agenda-table{
          .primary,
          .secondary,
          .info,
          .success,
          .danger,
          .warning,
          .secondary{
            background-color: transparent;
          }
          td{
            &:first-child{
              border-radius: 10px 0 0 0;
            }
          }
        }
      }
      .rbc-agenda-view table.rbc-agenda-table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
        border: 1px solid #323541;
      }
      .rbc-agenda-view table.rbc-agenda-table{
        thead{
          display: none;
        }
        tbody{
          >tr{
            &:not(:last-child){
              border-bottom: 1px solid #323541;
            }
            &:hover{
              box-shadow: 0 15px 40px rgba(146,153,184,.08);
              border-bottom-color: transparent;
            }
            
            >td{
              font-size: 14px;
              padding: 15px 20px;
              vertical-align: top;
            }
            .rbc-agenda-date-cell,
            .rbc-agenda-event-cell{
              font-weight: 500;
              color: #0A0A0A
            }
            .rbc-agenda-time-cell{
              span{
                font-size: 13px;
                position: relative;
                padding-left: 20px;
                color: #4D4F5A;
                &:after{
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  width: 10px;
                  height: 10px;
                  content: '';
                  border-radius: 50%;
                }
              }
            }
            &.primary{
              .rbc-agenda-time-cell{
                span{
                  &:after{
                    background-color: #8231D3;
                  }
                }
              }
            }
            &.secondary{
              .rbc-agenda-time-cell{
                span{
                  &:after{
                    background-color: #5840FF;
                  }
                }
              }
            }
            &.info{
              .rbc-agenda-time-cell{
                span{
                  &:after{
                    background-color: #00AAFF;
                  }
                }
              }
            }
            &.success{
              .rbc-agenda-time-cell{
                span{
                  &:after{
                    background-color: #01B81A;
                  }
                }
              }
            }
          }
        }
        .rbc-agenda-time-cell{
          padding-left: 15px;
          padding-right: 15px;
          text-transform: lowercase;
        }
      }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
        border-left-width: 0;
      }
      .rbc-agenda-time-cell {
        text-transform: lowercase;
      }
      .rbc-agenda-time-cell .rbc-continues-after:after {
        content: ' »';
      }
      .rbc-agenda-time-cell .rbc-continues-prior:before {
        content: '« ';
      }
      .rbc-agenda-date-cell,
      .rbc-agenda-time-cell {
        white-space: nowrap;
        font-size: 12px;
      }
      .rbc-agenda-event-cell {
        width: 100%;
        font-size: 12px;
      }
      .rbc-time-column {
        display: flex;
        flex-direction: column;
        min-height: 100%;

      }
      .rbc-time-column .rbc-timeslot-group {
        flex: 1;
      }
      .rbc-timeslot-group {
        min-height: 40px;
        display: flex;
        flex-flow: nowrap;
      }
      .rbc-time-gutter,
      .rbc-header-gutter {
        -webkit-flex: none;
        -ms-flex: none;
        flex: none;
      }
      .rbc-label {
        padding: 0 20px;
      }
      .rbc-day-slot {
        position: relative;
      }
      .rbc-day-slot .rbc-events-container {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        margin-right: 10px;
        top: 0;
        .rbc-addons-dnd-resizable{
          display: flex;
          flex-direction: column;
          padding-top: 8px;
          .rbc-event-content{
            order: -1;
            font-weight: 500;
          }
        }
      }
      .rbc-day-slot .rbc-events-container.rbc-is-rtl {
        left: 10px;
        right: 0;
      }
      .rbc-day-slot .rbc-event {
        display: flex;
        max-height: 100%;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        overflow: hidden;
        position: absolute;
      }
      .rbc-day-slot .rbc-event-label {
        flex: none;
        padding-right: 5px;
        width: auto;
      }
      .rbc-day-slot .rbc-event-content {
        width: 100%;
        flex: 1 1 0;
        word-wrap: break-word;
        line-height: 1;
        height: 100%;
        min-height: 1em;
      }
      .rbc-day-slot .rbc-time-slot {
        border-top: 1px solid transparent;
      }
      .rbc-time-view-resources .rbc-time-gutter,
      .rbc-time-view-resources .rbc-time-header-gutter {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        background-color: white;
        border-right: 1px solid #ddd;
        z-index: 10;
        margin-right: -1px;
      }
      .rbc-time-view-resources .rbc-time-header {
        overflow: hidden;
      }
      .rbc-time-view-resources .rbc-time-header-content {
        min-width: auto;
        flex: 1 0 0;
        flex-basis: 0px;
      }
      .rbc-time-view-resources .rbc-time-header-cell-single-day {
        display: none;
      }
      .rbc-time-view-resources .rbc-day-slot {
        min-width: 140px;
      }
      .rbc-time-view-resources .rbc-header,
      .rbc-time-view-resources .rbc-day-bg {
        width: 140px;
        -webkit-flex: 1 1 0;
        -ms-flex: 1 1 0px;
        flex: 1 1 0;
        -webkit-flex-basis: 0 px;
        -ms-flex-preferred-size: 0 px;
        flex-basis: 0 px;
      }
      .rbc-time-header-content + .rbc-time-header-content {
        margin-left: -1px;
      }
      .rbc-time-slot {
        -webkit-flex: 1 0 0;
        -ms-flex: 1 0 0px;
        flex: 1 0 0;
      }
      .rbc-time-slot.rbc-now {
        font-weight: bold;
      }
      .rbc-day-header {
        text-align: center;
      }
      .rbc-slot-selection {
        z-index: 10;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 75%;
        width: 100%;
        padding: 3px;
      }
      .rbc-slot-selecting {
        cursor: move;
      }
      .rbc-time-view {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        min-height: 0;
        border-radius: 10px;
        border: 1px solid #323541;
        .rbc-time-header{
          .rbc-label{
            background-color: #F8F9FB;
          }
          .rbc-header{
            border-left: 1px solid #323541;
          }
          
          .rbc-event{
            &.primary{
              background-color: #8231D315;
              color: #8231D3;
              &:after{
                background-color: #8231D3;
              }
            }
            &.secondary{
              background-color: #5840FF15;
              color: #5840FF;
              &:after{
                background-color: #5840FF;
              }
            }
            &.info{
              background-color: #00AAFF15;
              color: #00AAFF;
              &:after{
                background-color: #00AAFF;
              }
            }
            &.warning{
              background-color: #FA8B0C15;
              color: #FA8B0C;
              &:after{
                background-color: #FA8B0C;
              }
            }
            &.success{
              background-color: #01B81A15;
              color: #01B81A;
              &:after{
                background-color: #01B81A;
              }
            }
          }
        }
        .rbc-time-content{
          .rbc-time-column{
            .rbc-timeslot-group{
              align-items: center;
              justify-content: center;
              border-top: 1px solid #323541;
              .rbc-time-slot{
                span{
                  font-size: 14px;
                  display: inline-block;
                  color: gray;
                }
              }
            }
          }
          .rbc-day-slot {
            border-left: 1px solid #323541;
          }
          .rbc-event{
            box-shadow: 0 5px 8px rgb(10, 10, 10, .30);
            transition: all .3s ease;
            &:hover{
              transform: scale(1.07)
            }
            &.primary{
              background-color: #8231D3;
              color: #fff;
            }
            &.secondary{
              background-color: #5840FF;
              color: #fff;
            }
            &.info{
              background-color: #00AAFF;
              color: #fff;
            }
            &.warning{
              background-color: #FA8B0C;
              color: #fff;
            }
            &.success{
              background-color: #01B81A;
              color: #fff;
            }
            .rbc-event-content{
              font-size: 13px;
              margin-bottom: 4px;
            }
          }
        }
      }
      .rbc-time-view .rbc-time-gutter {
        white-space: nowrap;
      }
      .rbc-time-view .rbc-allday-cell {
        box-sizing: content-box;
        width: 100%;
        height: 100%;
        position: relative;
      }
      .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
        border-left: 1px solid #ddd;
      }
      .rbc-time-view .rbc-allday-events {
        position: relative;
        z-index: 4;
      }
      .rbc-time-view .rbc-row {
        box-sizing: border-box;
      }
      .rbc-time-header {
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        border-radius: 10px 10px 0 0;
        .rbc-time-header-gutter{
          border-radius: 10px 0 0 0;
        }
        .rbc-row-bg{
          .rbc-day-bg{
            &:first-child{
              border-left: 1px solid #323541;
            }
          }
        }
      }
      .rbc-time-header.rbc-overflowing {
      }
      .rbc-rtl .rbc-time-header.rbc-overflowing {
        border-right-width: 0;
      }
      .rbc-time-header > .rbc-row:first-child {
      }
      .rbc-time-header > .rbc-row.rbc-row-resource {
      }
      .rbc-time-header-cell-single-day {
        display: none;
      }
      .rbc-time-header-content {
        flex: 1;
        display: flex;
        min-width: 0;
        flex-direction: column;
        background-color: #1B1E2B;
        .rbc-header{
          border-radius: 0px;
          &:last-child{
            border-radius: 0 10px 0 0;
          }
        }
        .rbc-row{
          background-color: #1B1E2B;
        }
      }
      .rbc-rtl .rbc-time-header-content {
        border-left-width: 0;
      }
      .rbc-time-content {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 100%;
        overflow-y: auto;
        position: relative;
      }
      .rbc-time-content > .rbc-time-gutter {
        -webkit-flex: none;
        -ms-flex: none;
        flex: none;
      }
      .rbc-time-content > * + * > * {
      }
      .rbc-rtl .rbc-time-content > * + * > * {
        border-left-width: 0;
      }
      .rbc-time-content > .rbc-day-slot {
        width: 100%;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-select: none;
      }
      .rbc-current-time-indicator {
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        height: 1px;
        pointer-events: none;
      }
      button.rbc-button-link{
          border: 0 none;
          background-color: transparent;
      }
`;

export {Main, CalendarWrapper}
