import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {Pagination} from "src/entities/Country";
import {InputError} from "src/entities/Auth";
import {TutorGroupLesson} from "../../types";

export const getData = (state: StateSchema): Array<TutorGroupLesson> => state.tutorGroupLesson.data
export const getPagination = (state: StateSchema): Pagination => state.tutorGroupLesson.pagination
export const getFetching = (state: StateSchema): boolean => state.tutorGroupLesson.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.tutorGroupLesson.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.tutorGroupLesson.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.tutorGroupLesson.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.tutorGroupLesson.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.tutorGroupLesson.updateErrors
export const getIsFetchingAvailableTimes = (state: StateSchema): boolean => state.tutorGroupLesson.isFetchingAvailableTimes
