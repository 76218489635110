import {TutorGroupLesson, TutorGroupLessonSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getTutorGroupLessons} from "../services/getTutorGroupLessons";
import {storeTutorGroupLesson} from "../services/storeTutorGroupLesson";
import {updateTutorGroupLesson} from "../services/updateTutorGroupLesson";
import {getTutorGroupLesson} from "../services/getTutorGroupLesson";
import {getAvailableTimes} from "../services/getAvailableTimes";

const initialState: TutorGroupLessonSchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined,
    isFetchingAvailableTimes: false
}

export const tutorGroupLessonSlice = createSlice({
    name: 'tutorGroupLesson',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<TutorGroupLesson>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTutorGroupLessons.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getTutorGroupLessons.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getTutorGroupLessons.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getTutorGroupLesson.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getTutorGroupLesson.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getTutorGroupLesson.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeTutorGroupLesson.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeTutorGroupLesson.fulfilled, (state, action) => {
                state.isStoring = false
                state.storeErrors = undefined
            })
            .addCase(storeTutorGroupLesson.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateTutorGroupLesson.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateTutorGroupLesson.fulfilled, (state, action) => {
                state.isUpdating = false
                state.updateErrors = undefined
            })
            .addCase(updateTutorGroupLesson.rejected, (state, action) => {
                state.isUpdating = false
                //@ts-ignore
                state.updateErrors = action.payload
            })
            .addCase(getAvailableTimes.pending, (state, action) => {
                state.isFetchingAvailableTimes = true
            })
            .addCase(getAvailableTimes.fulfilled, (state, action) => {
                state.isFetchingAvailableTimes = false
            })
            .addCase(getAvailableTimes.rejected, (state, action) => {
                state.isFetchingAvailableTimes = false
            })
    }
})

export const {actions: tutorGroupLessonActions} = tutorGroupLessonSlice
export const {reducer: tutorGroupLessonReducer} = tutorGroupLessonSlice
