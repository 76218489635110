import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {Pagination} from "src/entities/Country";
import {InputError} from "src/entities/Auth";
import {TutorCategory} from "src/entities/TutorCategory";

export const getData = (state: StateSchema): Array<TutorCategory> => state.tutorCategory.data
export const getPagination = (state: StateSchema): Pagination => state.tutorCategory.pagination
export const getFetching = (state: StateSchema): boolean => state.tutorCategory.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.tutorCategory.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.tutorCategory.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.tutorCategory.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.tutorCategory.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.tutorCategory.updateErrors
