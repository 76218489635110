import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {Subject} from "../../types";
import {InputError} from "src/entities/Auth";
import {Pagination} from "src/entities/Country";

export const getData = (state: StateSchema): Array<Subject> => state.subject.data
export const getPagination = (state: StateSchema): Pagination => state.subject.pagination
export const getFetching = (state: StateSchema): boolean => state.subject.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.subject.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.subject.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.subject.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.subject.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.subject.updateErrors
export const getIsFetchingAll= (state: StateSchema): boolean => state.subject.isFetchingAll
