import {AuthSchema} from "../../types/index";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getCsrfToken} from "../services/getCsrfToken";
import {login} from "../services/login";
import {registerTutor} from "../services/registerTutor";
import {registerStudent} from "../services/registerStudent";

const initialState: AuthSchema = {
    data: {
        unauthorized: false,
        notFound: false,
        csrfToken: '',
        loginErrors: undefined,
        isLogging: false,
        isRegistering: false,
        registerErrors: undefined,
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUnauthorized: (state, action: PayloadAction<boolean>) => {
            state.data.unauthorized = action.payload
        },
        setNotFound: (state, action: PayloadAction<boolean>) => {
            state.data.notFound = action.payload
        },
        setLoginErrors: (state, action) => {
            state.data.loginErrors = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getCsrfToken.fulfilled, (state, action) => {
                //@ts-ignore
                state.data.csrfToken = action.payload
            })
            .addCase(login.fulfilled, (state, action) => {
                localStorage.setItem('token', action.payload.token)
                state.data.isLogging = false
            })
            .addCase(login.pending, (state, action) => {
                state.data.isLogging = true
            })
            .addCase(login.rejected, (state, action) => {
                // @ts-ignore
                state.data.loginErrors = action.payload
                state.data.isLogging = false
            })
            .addCase(registerTutor.fulfilled, (state, action) => {
                state.data.isRegistering = false
                localStorage.setItem('token', action.payload.token)
            })
            .addCase(registerTutor.pending, (state, action) => {
                state.data.isRegistering = true
            })
            .addCase(registerTutor.rejected, (state, action) => {
                // @ts-ignore
                state.data.registerErrors = action.payload
                state.data.isRegistering = false
            })
            .addCase(registerStudent.fulfilled, (state, action) => {
                state.data.isRegistering = false
                localStorage.setItem('token', action.payload.token)
            })
            .addCase(registerStudent.pending, (state, action) => {
                state.data.isRegistering = true
            })
            .addCase(registerStudent.rejected, (state, action) => {
                // @ts-ignore
                state.data.registerErrors = action.payload
                state.data.isRegistering = false
            })
    }
})

export const {actions: authActions} = authSlice
export const {reducer: authReducer} = authSlice
