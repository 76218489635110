import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {Test} from "../../types";
import {InputError} from "src/entities/Auth";
import {Pagination} from "src/entities/Country";

export const getData = (state: StateSchema): Array<Test> => state.test.data
export const getPagination = (state: StateSchema): Pagination => state.test.pagination
export const getFetching = (state: StateSchema): boolean => state.test.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.test.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.test.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.test.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.test.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.test.updateErrors
