import {LanguageLevel, LanguageLevelSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getLanguageLevels} from "../services/getLanguageLevels";
import {storeLanguageLevel} from "../services/storeLanguageLevel";
import {updateLanguageLevel} from "../services/updateLanguageLevel";
import {getLanguageLevel} from "../services/getLanguageLevel";
import {getAllLanguageLevels} from "../services/getAllLanguageLevels";

const initialState: LanguageLevelSchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined,
    isFetchingAll: false
}

export const languageLevelSlice = createSlice({
    name: 'languageLevel',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<LanguageLevel>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getLanguageLevels.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getLanguageLevels.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getLanguageLevels.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getLanguageLevel.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getLanguageLevel.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getLanguageLevel.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeLanguageLevel.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeLanguageLevel.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeLanguageLevel.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateLanguageLevel.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateLanguageLevel.fulfilled, (state, action) => {
                state.isUpdating = false
            })
            .addCase(getAllLanguageLevels.rejected, (state, action) => {
                state.isFetchingAll = false
            })
            .addCase(getAllLanguageLevels.fulfilled, (state, action) => {
                state.isFetchingAll = false
            })
            .addCase(getAllLanguageLevels.pending, (state, action) => {
                state.isFetchingAll = true
            })
    }
})

export const {actions: languageLevelActions} = languageLevelSlice
export const {reducer: languageLevelReducer} = languageLevelSlice
