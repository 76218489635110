import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {Language} from "src/entities/Language";
import {Pagination} from "src/entities/Country";
import {InputError} from "src/entities/Auth";

export const getData = (state: StateSchema): Array<Language> => state.language.data
export const getPagination = (state: StateSchema): Pagination => state.language.pagination
export const getFetching = (state: StateSchema): boolean => state.language.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.language.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.language.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.language.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.language.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.language.updateErrors
export const getIsFetchingAll = (state: StateSchema): boolean => state.language.isFetchingAll
