import classes from './index.module.css'
import React from "react";
import {AuthHeader} from "src/widgets/AuthHeader";

const AuthLayout = (props: React.PropsWithChildren) => {
    return (
        <div className={classes.authLayout}>
            <AuthHeader />
            {props.children}
        </div>
    )
}

export default AuthLayout
