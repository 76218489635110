import {RoutesPath} from "src/shared/config/routes/index";
import {ReactComponent as Home} from 'src/shared/assets/icons/home.svg'
import {ReactComponent as Country} from 'src/shared/assets/icons/global.svg'
import {ReactComponent as Subject} from 'src/shared/assets/icons/subject.svg'
import {ReactComponent as Language} from 'src/shared/assets/icons/language.svg'
import {ReactComponent as TutorCategory} from 'src/shared/assets/icons/category.svg'
import {ReactComponent as User} from 'src/shared/assets/icons/user.svg'
import {ReactComponent as List} from 'src/shared/assets/icons/list.svg'
import {ReactComponent as Literature} from 'src/shared/assets/icons/literature.svg'
import {ReactComponent as Test} from 'src/shared/assets/icons/test.svg'
import {ReactComponent as LanguageLevel} from 'src/shared/assets/icons/language_level.svg'

export interface SidebarItem {
    label: string
    path: string
    icon: () => any
}
export const AdminSidebarItems: Array<SidebarItem> = [
    {
        label: 'Аналитика',
        path: RoutesPath.main_admin,
        icon: function () {
            return <Home width={20} height={20} />
        }
    },
    {
        label: 'Страны',
        path: RoutesPath.countries,
        icon: function () {
            return <Country width={20} height={20} />
        }
    },
    {
        label: 'Предметы',
        path: RoutesPath.subjects,
        icon: function () {
            return <Subject width={20} height={20} />
        }
    },
    {
        label: 'Языки',
        path: RoutesPath.languages,
        icon: function () {
            return <Language width={20} height={20} />
        }
    },
    {
        label: 'Категории учителей',
        path: RoutesPath.tutor_categories,
        icon: function () {
            return <TutorCategory width={20} height={20} />
        }
    },
    {
        label: 'Пользователи',
        path: RoutesPath.users,
        icon: function () {
            return <User width={20} height={20} />
        }
    },
    {
        label: 'Литература',
        path: RoutesPath.literatures,
        icon: function () {
            return <Literature width={20} height={20} />
        }
    },
    {
        label: 'Тесты',
        path: RoutesPath.tests,
        icon: function () {
            return <Test width={20} height={20} />
        }
    },
    {
        label: 'Уровни языка',
        path: RoutesPath.language_level,
        icon: function () {
            return <LanguageLevel width={20} height={20} />
        }
    },
]

export const ModeratorSidebarItems: Array<SidebarItem> = [
    {
        label: 'Главная',
        path: RoutesPath.main_moderator,
        icon: function () {
            return <Home width={20} height={20} />
        }
    },
    {
        label: 'Список репетиторов',
        path: RoutesPath.moderator_tutor_list,
        icon: function () {
            return <List width={20} height={20} />
        }
    },
    {
        label: 'Заявки учеников',
        path: RoutesPath.moderator_student_list,
        icon: function () {
            return <List width={20} height={20} />
        }
    },
    {
        label: 'Отчет',
        path: RoutesPath.moderator_reports,
        icon: function () {
            return <Language width={20} height={20} />
        }
    }
]

export const TutorSidebarItems: Array<SidebarItem> = [
    {
        label: 'Профиль',
        path: RoutesPath.tutor_profile,
        icon: function () {
            return <User width={20} height={20} />
        }
    },
    {
        label: 'Мои уроки',
        path: RoutesPath.main_tutor,
        icon: function () {
            return <List width={20} height={20} />
        }
    },
    {
        label: 'Список тестов',
        path: RoutesPath.tutor_students,
        icon: function () {
            return <Test width={20} height={20} />
        }
    },
    {
        label: 'Список литературы',
        path: RoutesPath.tutor_literatures,
        icon: function () {
            return <Literature width={20} height={20} />
        }
    },
    // {
    //     label: 'Расписание',
    //     path: RoutesPath.tutor_schedule,
    //     icon: function () {
    //         return <Calendar width={20} height={20} />
    //     }
    // },
    {
        label: 'Групповые уроки',
        path: RoutesPath.tutor_group_lessons,
        icon: function () {
            return <Subject width={20} height={20} />
        }
    }
]

export const StudentSidebarItems: Array<SidebarItem> = [
    // {
    //     label: 'Главная',
    //     path: RoutesPath.main_student,
    //     icon: function () {
    //         return <Home width={20} height={20} />
    //     }
    // },
    {
        label: 'Список уроков',
        path: RoutesPath.main_student,
        icon: function () {
            return <List width={20} height={20} />
        }
    },
    {
        label: 'Список тестов',
        path: RoutesPath.student_tests,
        icon: function () {
            return <Test width={20} height={20} />
        }
    },
    {
        label: 'Список литературы',
        path: RoutesPath.student_literatures,
        icon: function () {
            return <Literature width={20} height={20} />
        }
    },
]
