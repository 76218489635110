import {createAsyncThunk} from "@reduxjs/toolkit";
import {APP_URL} from "src/shared/constants/api";
import {getCookie} from "src/shared/utils/getCookie";

export const getCsrfToken = createAsyncThunk(
    'auth/csrfToken',
    async () => {
        try {
            await fetch(APP_URL + '/sanctum/csrf-cookie', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Referer': 'http://localhost:3000',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                credentials: 'include'
            })

        } catch (error) {
            console.log('Error in getting csrf-token:', error)
        }
    }
)
