import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {Literature} from "../../types";
import {InputError} from "src/entities/Auth";
import {Pagination} from "src/entities/Country";

export const getData = (state: StateSchema): Array<Literature> => state.literature.data
export const getPagination = (state: StateSchema): Pagination => state.literature.pagination
export const getFetching = (state: StateSchema): boolean => state.literature.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.literature.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.literature.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.literature.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.literature.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.literature.updateErrors
