import {configureStore} from "@reduxjs/toolkit";
import {userReducer} from "src/entities/User";
import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {authReducer} from "src/entities/Auth";
import {roleReducer} from "src/entities/Role";
import {countryReducer} from "src/entities/Country";
import {subjectReducer} from "src/entities/Subject";
import {languageReducer} from "src/entities/Language";
import {tutorCategoryReducer} from "src/entities/TutorCategory";
import {literatureReducer} from "src/entities/Literature";
import {testReducer} from "src/entities/Test";
import {testQuestionReducer} from "src/entities/TestQuestion";
import {testQuestionAnswerReducer} from "src/entities/TestQuestionAnswer";
import {toastReducer} from "src/entities/Toast";
import {languageLevelReducer} from "src/entities/LanguageLevel";
import {tutorScheduleReducer} from "src/entities/TutorSchedule";
import {tutorGroupLessonReducer} from "src/entities/TutorGroupLesson";
import {menuReducer} from "src/entities/Menu";

export function createReduxStore(initialState: StateSchema) {
    const rootReducers = {
        user: userReducer,
        auth: authReducer,
        role: roleReducer,
        country: countryReducer,
        subject: subjectReducer,
        language: languageReducer,
        tutorCategory: tutorCategoryReducer,
        literature: literatureReducer,
        test: testReducer,
        testQuestion: testQuestionReducer,
        testQuestionAnswer: testQuestionAnswerReducer,
        toast: toastReducer,
        languageLevel: languageLevelReducer,
        tutorSchedule: tutorScheduleReducer,
        tutorGroupLesson: tutorGroupLessonReducer,
        menu: menuReducer,
    }

    return configureStore({
        reducer: rootReducers,
        devTools: true,
        preloadedState: initialState
    })
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch']
