import {useSelector} from "react-redux";
import {useLocation, Navigate} from "react-router-dom";
import {RoutesConfig, RoutesConfigItem, RoutesPath} from "src/shared/config/routes";
import {getAuthUserData} from "src/entities/User";
import React from "react";
import {Roles} from "src/shared/config/routes/roles";

interface RequireAuthenticationProps extends React.PropsWithChildren {
    route: RoutesConfigItem
}

const RequireAuthentication = (props: RequireAuthenticationProps): any => {
    const authData = useSelector(getAuthUserData)
    const location = useLocation()

    if (props.route.require_auth) {
        if (authData === undefined) {
            return <Navigate to={RoutesPath.login} state={{from: location}}/>
        }
    } else {
        if (authData !== undefined && !props.route.allow_with_auth) {
            if (authData.role === Roles.ADMIN) {
                return <Navigate to={RoutesConfig.main_admin.path} state={{from: location.pathname}}/>
            } else if (authData.role === Roles.TUTOR) {
                return <Navigate to={RoutesConfig.main_tutor.path} state={{from: location.pathname}}/>
            } else if (authData.role === Roles.STUDENT) {
                return <Navigate to={RoutesConfig.main_admin.path} state={{from: location.pathname}}/>
            } else if (authData.role === Roles.MODERATOR) {
                return <Navigate to={RoutesConfig.main_moderator.path} state={{from: location.pathname}}/>
            }
        }
    }

    return props.children
}

export default RequireAuthentication
