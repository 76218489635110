import React, {useEffect, useState} from 'react';
import {className} from 'src/shared/utils/className';
import {AppRouter} from 'src/app/providers/Router'
import {useAppDispatch} from "src/shared/hooks/useAppDispatch";
import {getCsrfToken} from "src/entities/Auth";
import {getAuthUser, getAuthUserData, getIsFetchingAuthData, User} from "src/entities/User";
import {Roles} from "src/shared/config/routes/roles";
import {useNavigate} from "react-router-dom";
import {RoutesPath} from "src/shared/config/routes";
import {useSelector} from "react-redux";

function App() {
    const dispatch = useAppDispatch()
    // const [loading, setLoading] = useState(true)
    const auth = useSelector(getAuthUserData)
    const isFetching = useSelector(getIsFetchingAuthData)
    // const [authData, setAuthData] = useState<User | undefined>(undefined)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getCsrfToken())
            const data = await dispatch(getAuthUser())
            if (data.type.includes('fulfilled')) {
                console.log("AuthData:", data.payload)
            }
            // setAuthData(data.payload)
            // setLoading(false)
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (auth && auth.role === Roles.TUTOR && !auth.is_verified) {
            navigate(RoutesPath.tutor_stepper)
        }
    }, [auth])

    if (isFetching) {
        return <div>LOADING...</div>
    }

      return (
          <div className={className('app')}>
            <AppRouter />
          </div>
      );
}

export default App;
