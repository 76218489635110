import {User, UserSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getAuthUser} from "../services/getAuthUser";
import {getModerators} from "../services/getModerators";
import {getModerator} from "../services/getModerator";
import {storeModerator} from "../services/storeModerator";
import {updateModerator} from "../services/updateModerator";
import {storeTutorRequest} from "../services/storeTutorRequest";
import {getStepperRequest} from "../services/getStepperRequest";
import {getTutors} from "../services/getTutors";
import {getTutorRequests} from "../services/getTutorRequests";
import {getTutorRequest} from "../services/getTutorRequest";
import {storeModeratorRequest} from "../services/storeModeratorRequest";
import {getTutor} from "../services/getTutor";
import {getTimezones} from "../services/getTimezones";
import {submitTutorRequest} from "../services/submitTutorRequest";
import {bookLesson} from "../services/bookLesson";
import {getStudentRequests} from "../services/getStudentRequests";
import { getGroupRequests } from "../services/getGroupRequests";
import { getTrialRequests } from "../services/getTrialRequests";
import {getStudentRequest} from "../services/getStudentRequest";
import {storeModeratorStudentRequest} from "../services/storeModeratorStudentRequest";
import {getTutorGroupLessons} from "../services/getTutorGroupLessons";
import {getTutorSchedules} from "../services/getTutorSchedules";
import {bookGroupLesson} from "../services/bookGroupLesson";
import {getStudentTests} from "../services/getStudentTests";
import {getStudentTest} from "../services/getStudentTest";
import {completeStudentTest} from "../services/completeStudentTest";
import {getStudentLiteratures} from "../services/getStudentLiteratures";
import {getStudentPendingLessons} from "../services/getStudentPendingLessons";
import {getAllStudentLessons} from "../services/getAllStudentLessons";
import {getAllLiteratures} from "../services/getAllLiteratures";
import {getAllTests} from "../services/getAllTests";
import {saveTutorLiteratures} from "../services/saveTutorLiteratures";
import {saveTutorTests} from "../services/saveTutorTests";
import {deleteTutorLiterature} from "../services/deleteTutorLiterature";
import {deleteTutorTest} from "../services/deleteTutorTest";
import {getTutorLiteratures} from "../services/getTutorLiteratures";
import {getTutorTests} from "../services/getTutorTests";
import {getTutorTest} from "../services/getTutorTest";
import {declineModeratorStudentRequest} from "../services/declineModeratorStudentRequest";
import {getStudentPendingLesson} from "../services/getStudentPendingLesson";
import {getAllTutorLessons} from "../services/getAllTutorLessons";
import {getTutorLesson} from "../services/getTutorLesson";
import {getStudentCurrentLesson} from "../services/getStudentCurrentLesson";
import {getStudentLesson} from "../services/getStudentLesson";
import {getTutorStudents} from "../services/getTutorStudents";
import {getTutorDataStep} from "../services/getTutorDataStep";
import {getTutorPhotoStep} from "../services/getTutorPhotoStep";
import {getTutorCertificationStep} from "../services/getTutorCertificationStep";
import {getTutorEducationStep} from "../services/getTutorEducationStep";
import {getTutorDescriptionStep} from "../services/getTutorDescriptionStep";
import {getTutorVideoStep} from "../services/getTutorVideoStep";
import {getTutorScheduleStep} from "../services/getTutorScheduleStep";
import {getTutorPricingStep} from "../services/getTutorPricingStep";
import {updateTutorDataStep} from "../services/updateTutorDataStep";
import {updateTutorPhotoStep} from "../services/updateTutorPhotoStep";
import {updateTutorCertificationStep} from "../services/updateTutorCertificationStep";
import {updateTutorEducationStep} from "../services/updateTutorEducationStep";
import {updateTutorDescriptionStep} from "../services/updateTutorDescriptionStep";
import {updateTutorVideoStep} from "../services/updateTutorVideoStep";
import {updateTutorScheduleStep} from "../services/updateTutorScheduleStep";
import {updateTutorPricingStep} from "../services/updateTutorPricingStep";
import {storeReview} from "../services/storeReview";

const initialState: UserSchema = {
    authData: undefined,
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined,
    tutorStepsErrors: undefined,
    isTutorRequesting: false,
    tutorRequest: undefined,
    isApproving: false,
    isFetchingTutors: false,
    isFetchingTutorRequests: false,
    tutorRequests: undefined,
    isFetchingTutorRequest: false,
    isStoringModeratorRequest: false,
    tutors: [],
    isFetchingTutor: false,
    isFetchingTimezones: false,
    isFetchingAuthData: true,
    isFetchingStepperRequest: false,
    isSubmitting: false,
    isBookingLesson: false,
    isFetchingStudentRequests: false,
    isFetchingGroupRequests: false,
    isFetchingStudentRequest: false,
    studentRequests: undefined,
    groupRequests: undefined,
    trialRequests: undefined,
    isStoringStudentRequest: false,
    getTutorError: '',
    isFetchingTutorGroupLessons: false,
    isFetchingTutorSchedules: false,
    isFetchingStudentTests: false,
    isFetchingStudentTest: false,
    isCompletingStudentTest: false,
    isFetchingStudentLiteratures: false,
    isFetchingStudentPendingLessons: false,
    isFetchingAllStudentLessons: false,
    isFetchingAllLiteratures: false,
    isFetchingAllTests: false,
    isSavingTutorLiteratures: false,
    isSavingTutorTests: false,
    isDeletingTutorLiterature: false,
    isDeletingTutorTest: false,
    isFetchingTutorLiteratures: false,
    isFetchingTutorTests: false,
    isFetchingTutorTest: false,
    isDecliningStudentRequest: false,
    isFetchingStudentPendingLesson: false,
    isFetchingAllTutorLessons: false,
    isFetchingTutorLesson: false,
    isFetchingStudentCurrentLesson: false,
    isFetchingStudentLesson: false,
    isFetchingTutorStudents: false,
    tutorStudents: [],
    tutorTests: [],
    isFetchingTutorStep: false,
    isUpdatingTutorStep: false,
    isStoringReview: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthData: (state, action: PayloadAction<User | undefined>) => {
            state.authData = action.payload
        },
        setData: (state, action: PayloadAction<Array<User>>) => {
            state.data = action.payload
        },
        setTutorRequest: (state, action: PayloadAction<any>) => {
            state.tutorRequest = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getAuthUser.fulfilled, (state, action) => {
                state.authData = action.payload
                state.isFetchingAuthData = false
            })
            .addCase(getAuthUser.rejected, (state, action) => {
                state.isFetchingAuthData = false
            })
            .addCase(getAuthUser.pending, (state, action) => {
                state.isFetchingAuthData = true
            })
            .addCase(getModerators.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getModerators.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getModerators.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getModerator.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getModerator.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getModerator.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeModerator.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeModerator.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeModerator.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateModerator.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateModerator.fulfilled, (state, action) => {
                state.isUpdating = false
            })
            .addCase(updateModerator.rejected, (state, action) => {
                state.isUpdating = false
                //@ts-ignore
                state.updateErrors = action.payload
            })
            .addCase(storeTutorRequest.pending, (state, action) => {
                state.isTutorRequesting = true
            })
            .addCase(storeTutorRequest.fulfilled, (state, action) => {
                state.isTutorRequesting = false
                state.tutorStepsErrors = undefined
            })
            .addCase(storeTutorRequest.rejected, (state, action) => {
                state.isTutorRequesting = false
                //@ts-ignore
                state.tutorStepsErrors = action.payload
            })
            .addCase(getStepperRequest.fulfilled, (state, action) => {
                state.tutorRequest = action.payload
                state.isApproving = false
                state.isFetchingStepperRequest = false
            })
            .addCase(getStepperRequest.rejected, (state, action) => {
                state.isApproving = true
                state.isFetchingStepperRequest = false
            })
            .addCase(getStepperRequest.pending, (state, action) => {
                state.isFetchingStepperRequest = true
            })
            .addCase(getTutors.fulfilled, (state, action) => {
                state.isFetchingTutors = false
                state.tutors = action.payload
            })
            .addCase(getTutors.rejected, (state, action) => {
                state.isFetchingTutors = false
            })
            .addCase(getTutors.pending, (state, action) => {
                state.isFetchingTutors = true
            })
            .addCase(getTutorRequests.fulfilled, (state, action) => {
                state.isFetchingTutorRequests = false
                // @ts-ignore
                state.tutorRequests = action.payload.data
                // @ts-ignore
                state.pagination = action.payload.pagination
            })
            .addCase(getTutorRequests.rejected, (state, action) => {
                state.isFetchingTutorRequests = false
            })
            .addCase(getTutorRequests.pending, (state, action) => {
                state.isFetchingTutorRequests = true
            })
            .addCase(getStudentRequests.fulfilled, (state, action) => {
                state.isFetchingStudentRequests = false
                // @ts-ignore
                state.studentRequests = action.payload.data
                // @ts-ignore
                state.pagination = action.payload.pagination
            })
            .addCase(getStudentRequests.rejected, (state, action) => {
                state.isFetchingStudentRequests = false
            })
            .addCase(getStudentRequests.pending, (state, action) => {
                state.isFetchingStudentRequests = true
            })
            .addCase(getGroupRequests.fulfilled, (state, action) => {
                state.isFetchingStudentRequests = false
                // @ts-ignore
                state.groupRequests = action.payload.data
                // @ts-ignore
                state.pagination = action.payload.pagination
            })
            .addCase(getGroupRequests.rejected, (state, action) => {
                state.isFetchingStudentRequests = false
            })
            .addCase(getGroupRequests.pending, (state, action) => {
                state.isFetchingStudentRequests = true
            })
            .addCase(getTrialRequests.fulfilled, (state, action) => {
                state.isFetchingStudentRequests = false
                // @ts-ignore
                state.trialRequests = action.payload.data
                // @ts-ignore
                state.pagination = action.payload.pagination
            })
            .addCase(getTrialRequests.rejected, (state, action) => {
                state.isFetchingStudentRequests = false
            })
            .addCase(getTrialRequests.pending, (state, action) => {
                state.isFetchingStudentRequests = true
            })
            .addCase(getTutorRequest.fulfilled, (state, action) => {
                state.isFetchingTutorRequest = false
            })
            .addCase(getTutorRequest.rejected, (state, action) => {
                state.isFetchingTutorRequest = false
            })
            .addCase(getTutorRequest.pending, (state, action) => {
                state.isFetchingTutorRequest = true
            })
            .addCase(getStudentRequest.fulfilled, (state, action) => {
                state.isFetchingStudentRequest = false
            })
            .addCase(getStudentRequest.rejected, (state, action) => {
                state.isFetchingStudentRequest = false
            })
            .addCase(getStudentRequest.pending, (state, action) => {
                state.isFetchingStudentRequest = true
            })
            .addCase(storeModeratorRequest.fulfilled, (state, action) => {
                state.isStoringModeratorRequest = false
            })
            .addCase(storeModeratorRequest.rejected, (state, action) => {
                state.isStoringModeratorRequest = false
            })
            .addCase(storeModeratorRequest.pending, (state, action) => {
                state.isStoringModeratorRequest = true
            })
            .addCase(getTutor.fulfilled, (state, action) => {
                state.isFetchingTutor = false
            })
            .addCase(getTutor.rejected, (state, action) => {
                state.isFetchingTutor = false
                // @ts-ignore
                state.getTutorError = action.payload
            })
            .addCase(getTutor.pending, (state, action) => {
                state.isFetchingTutor = true
            })
            .addCase(getTimezones.fulfilled, (state, action) => {
                state.isFetchingTimezones = false
            })
            .addCase(getTimezones.rejected, (state, action) => {
                state.isFetchingTimezones = false
            })
            .addCase(getTimezones.pending, (state, action) => {
                state.isFetchingTimezones = true
            })
            .addCase(submitTutorRequest.fulfilled, (state, action) => {
                state.isSubmitting = false
            })
            .addCase(submitTutorRequest.rejected, (state, action) => {
                state.isSubmitting = false
            })
            .addCase(submitTutorRequest.pending, (state, action) => {
                state.isSubmitting = true
            })
            .addCase(bookLesson.fulfilled, (state, action) => {
                state.isBookingLesson = false
            })
            .addCase(bookLesson.rejected, (state, action) => {
                state.isBookingLesson = false
            })
            .addCase(bookLesson.pending, (state, action) => {
                state.isBookingLesson = true
            })
            .addCase(storeModeratorStudentRequest.fulfilled, (state, action) => {
                state.isStoringStudentRequest = false
            })
            .addCase(storeModeratorStudentRequest.rejected, (state, action) => {
                state.isStoringStudentRequest = false
            })
            .addCase(storeModeratorStudentRequest.pending, (state, action) => {
                state.isStoringStudentRequest = true
            })
            .addCase(declineModeratorStudentRequest.fulfilled, (state, action) => {
                state.isDecliningStudentRequest = false
            })
            .addCase(declineModeratorStudentRequest.rejected, (state, action) => {
                state.isDecliningStudentRequest = false
            })
            .addCase(declineModeratorStudentRequest.pending, (state, action) => {
                state.isDecliningStudentRequest = true
            })
            .addCase(getTutorGroupLessons.fulfilled, (state, action) => {
                state.isFetchingTutorGroupLessons = false
            })
            .addCase(getTutorGroupLessons.rejected, (state, action) => {
                state.isFetchingTutorGroupLessons = false
            })
            .addCase(getTutorGroupLessons.pending, (state, action) => {
                state.isFetchingTutorGroupLessons = true
            })
            .addCase(getTutorSchedules.fulfilled, (state, action) => {
                state.isFetchingTutorSchedules = false
            })
            .addCase(getTutorSchedules.rejected, (state, action) => {
                state.isFetchingTutorSchedules = false
            })
            .addCase(getTutorSchedules.pending, (state, action) => {
                state.isFetchingTutorSchedules = true
            })
            .addCase(bookGroupLesson.fulfilled, (state, action) => {
                state.isBookingLesson = false
            })
            .addCase(bookGroupLesson.rejected, (state, action) => {
                state.isBookingLesson = false
            })
            .addCase(bookGroupLesson.pending, (state, action) => {
                state.isBookingLesson = true
            })
            .addCase(getStudentTests.fulfilled, (state, action) => {
                state.isFetchingStudentTests = false
            })
            .addCase(getStudentTests.rejected, (state, action) => {
                state.isFetchingStudentTests = false
            })
            .addCase(getStudentTests.pending, (state, action) => {
                state.isFetchingStudentTests = true
            })
            .addCase(getStudentTest.fulfilled, (state, action) => {
                state.isFetchingStudentTest = false
            })
            .addCase(getStudentTest.rejected, (state, action) => {
                state.isFetchingStudentTest = false
            })
            .addCase(getStudentTest.pending, (state, action) => {
                state.isFetchingStudentTest = true
            })
            .addCase(completeStudentTest.fulfilled, (state, action) => {
                state.isCompletingStudentTest = false
            })
            .addCase(completeStudentTest.rejected, (state, action) => {
                state.isCompletingStudentTest = false
            })
            .addCase(completeStudentTest.pending, (state, action) => {
                state.isCompletingStudentTest = true
            })
            .addCase(getStudentLiteratures.fulfilled, (state, action) => {
                state.isFetchingStudentLiteratures = false
            })
            .addCase(getStudentLiteratures.rejected, (state, action) => {
                state.isFetchingStudentLiteratures = false
            })
            .addCase(getStudentLiteratures.pending, (state, action) => {
                state.isFetchingStudentLiteratures = true
            })
            .addCase(getStudentPendingLessons.fulfilled, (state, action) => {
                state.isFetchingStudentPendingLessons = false
            })
            .addCase(getStudentPendingLessons.rejected, (state, action) => {
                state.isFetchingStudentPendingLessons = false
            })
            .addCase(getStudentPendingLessons.pending, (state, action) => {
                state.isFetchingStudentPendingLessons = true
            })
            .addCase(getAllStudentLessons.fulfilled, (state, action) => {
                state.isFetchingAllStudentLessons = false
            })
            .addCase(getAllStudentLessons.rejected, (state, action) => {
                state.isFetchingAllStudentLessons = false
            })
            .addCase(getAllStudentLessons.pending, (state, action) => {
                state.isFetchingAllStudentLessons = true
            })
            .addCase(getAllLiteratures.fulfilled, (state, action) => {
                state.isFetchingAllLiteratures = false
            })
            .addCase(getAllLiteratures.rejected, (state, action) => {
                state.isFetchingAllLiteratures = false
            })
            .addCase(getAllLiteratures.pending, (state, action) => {
                state.isFetchingAllLiteratures = true
            })
            .addCase(getAllTests.fulfilled, (state, action) => {
                state.isFetchingAllTests = false
            })
            .addCase(getAllTests.rejected, (state, action) => {
                state.isFetchingAllTests = false
            })
            .addCase(getAllTests.pending, (state, action) => {
                state.isFetchingAllTests = true
            })
            .addCase(saveTutorLiteratures.fulfilled, (state, action) => {
                state.isSavingTutorLiteratures = false
            })
            .addCase(saveTutorLiteratures.rejected, (state, action) => {
                state.isSavingTutorLiteratures = false
            })
            .addCase(saveTutorLiteratures.pending, (state, action) => {
                state.isSavingTutorLiteratures = true
            })
            .addCase(saveTutorTests.fulfilled, (state, action) => {
                state.isSavingTutorTests = false
            })
            .addCase(saveTutorTests.rejected, (state, action) => {
                state.isSavingTutorTests = false
            })
            .addCase(saveTutorTests.pending, (state, action) => {
                state.isSavingTutorTests = true
            })
            .addCase(deleteTutorLiterature.fulfilled, (state, action) => {
                state.isDeletingTutorLiterature = false
            })
            .addCase(deleteTutorLiterature.rejected, (state, action) => {
                state.isDeletingTutorLiterature = false
            })
            .addCase(deleteTutorLiterature.pending, (state, action) => {
                state.isDeletingTutorLiterature = true
            })
            .addCase(deleteTutorTest.fulfilled, (state, action) => {
                state.isDeletingTutorTest = false
            })
            .addCase(deleteTutorTest.rejected, (state, action) => {
                state.isDeletingTutorTest = false
            })
            .addCase(deleteTutorTest.pending, (state, action) => {
                state.isDeletingTutorTest = true
            })
            .addCase(getTutorLiteratures.fulfilled, (state, action) => {
                state.isFetchingTutorLiteratures = false
            })
            .addCase(getTutorLiteratures.rejected, (state, action) => {
                state.isFetchingTutorLiteratures = false
            })
            .addCase(getTutorLiteratures.pending, (state, action) => {
                state.isFetchingTutorLiteratures = true
            })
            .addCase(getTutorTests.fulfilled, (state, action) => {
                //@ts-ignore
                state.tutorTests = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.isFetchingTutorTests = false
            })
            .addCase(getTutorTests.rejected, (state, action) => {
                state.isFetchingTutorTests = false
            })
            .addCase(getTutorTests.pending, (state, action) => {
                state.isFetchingTutorTests = true
            })
            .addCase(getTutorTest.fulfilled, (state, action) => {
                state.isFetchingTutorTest = false
            })
            .addCase(getTutorTest.rejected, (state, action) => {
                state.isFetchingTutorTest = false
            })
            .addCase(getTutorTest.pending, (state, action) => {
                state.isFetchingTutorTest = true
            })
            .addCase(getStudentPendingLesson.fulfilled, (state, action) => {
                state.isFetchingStudentPendingLesson = false
            })
            .addCase(getStudentPendingLesson.rejected, (state, action) => {
                state.isFetchingStudentPendingLesson = false
            })
            .addCase(getStudentPendingLesson.pending, (state, action) => {
                state.isFetchingStudentPendingLesson = true
            })
            .addCase(getAllTutorLessons.fulfilled, (state, action) => {
                state.isFetchingAllTutorLessons = false
            })
            .addCase(getAllTutorLessons.rejected, (state, action) => {
                state.isFetchingAllTutorLessons = false
            })
            .addCase(getAllTutorLessons.pending, (state, action) => {
                state.isFetchingAllTutorLessons = true
            })
            .addCase(getTutorLesson.fulfilled, (state, action) => {
                state.isFetchingTutorLesson = false
            })
            .addCase(getTutorLesson.rejected, (state, action) => {
                state.isFetchingTutorLesson = false
            })
            .addCase(getTutorLesson.pending, (state, action) => {
                state.isFetchingTutorLesson = true
            })
            .addCase(getStudentCurrentLesson.fulfilled, (state, action) => {
                state.isFetchingStudentCurrentLesson = false
            })
            .addCase(getStudentCurrentLesson.rejected, (state, action) => {
                state.isFetchingStudentCurrentLesson = false
            })
            .addCase(getStudentCurrentLesson.pending, (state, action) => {
                state.isFetchingStudentCurrentLesson = true
            })
            .addCase(getStudentLesson.fulfilled, (state, action) => {
                state.isFetchingStudentLesson = false
            })
            .addCase(getStudentLesson.rejected, (state, action) => {
                state.isFetchingStudentLesson = false
            })
            .addCase(getStudentLesson.pending, (state, action) => {
                state.isFetchingStudentLesson = true
            })
            .addCase(getTutorStudents.fulfilled, (state, action) => {
                //@ts-ignore
                state.tutorStudents = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.isFetchingTutorStudents = false
            })
            .addCase(getTutorStudents.rejected, (state, action) => {
                state.isFetchingTutorStudents = false
            })
            .addCase(getTutorStudents.pending, (state, action) => {
                state.isFetchingTutorStudents = true
            })
            .addCase(getTutorDataStep.fulfilled, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorDataStep.rejected, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorDataStep.pending, (state, action) => {
                state.isFetchingTutorStep = true
            })
            .addCase(getTutorPhotoStep.fulfilled, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorPhotoStep.rejected, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorPhotoStep.pending, (state, action) => {
                state.isFetchingTutorStep = true
            })
            .addCase(getTutorCertificationStep.fulfilled, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorCertificationStep.rejected, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorCertificationStep.pending, (state, action) => {
                state.isFetchingTutorStep = true
            })
            .addCase(getTutorEducationStep.fulfilled, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorEducationStep.rejected, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorEducationStep.pending, (state, action) => {
                state.isFetchingTutorStep = true
            })
            .addCase(getTutorDescriptionStep.fulfilled, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorDescriptionStep.rejected, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorDescriptionStep.pending, (state, action) => {
                state.isFetchingTutorStep = true
            })
            .addCase(getTutorVideoStep.fulfilled, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorVideoStep.rejected, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorVideoStep.pending, (state, action) => {
                state.isFetchingTutorStep = true
            })
            .addCase(getTutorScheduleStep.fulfilled, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorScheduleStep.rejected, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorScheduleStep.pending, (state, action) => {
                state.isFetchingTutorStep = true
            })
            .addCase(getTutorPricingStep.fulfilled, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorPricingStep.rejected, (state, action) => {
                state.isFetchingTutorStep = false
            })
            .addCase(getTutorPricingStep.pending, (state, action) => {
                state.isFetchingTutorStep = true
            })
            .addCase(updateTutorDataStep.fulfilled, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorDataStep.rejected, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorDataStep.pending, (state, action) => {
                state.isUpdatingTutorStep = true
            })
            .addCase(updateTutorPhotoStep.rejected, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorPhotoStep.pending, (state, action) => {
                state.isUpdatingTutorStep = true
            })
            .addCase(updateTutorPhotoStep.fulfilled, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorCertificationStep.fulfilled, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorCertificationStep.rejected, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorCertificationStep.pending, (state, action) => {
                state.isUpdatingTutorStep = true
            })
            .addCase(updateTutorEducationStep.fulfilled, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorEducationStep.rejected, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorEducationStep.pending, (state, action) => {
                state.isUpdatingTutorStep = true
            })
            .addCase(updateTutorDescriptionStep.fulfilled, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorDescriptionStep.rejected, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorDescriptionStep.pending, (state, action) => {
                state.isUpdatingTutorStep = true
            })
            .addCase(updateTutorVideoStep.fulfilled, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorVideoStep.rejected, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorVideoStep.pending, (state, action) => {
                state.isUpdatingTutorStep = true
            })
            .addCase(updateTutorScheduleStep.fulfilled, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorScheduleStep.rejected, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorScheduleStep.pending, (state, action) => {
                state.isUpdatingTutorStep = true
            })
            .addCase(updateTutorPricingStep.fulfilled, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorPricingStep.rejected, (state, action) => {
                state.isUpdatingTutorStep = false
            })
            .addCase(updateTutorPricingStep.pending, (state, action) => {
                state.isUpdatingTutorStep = true
            })
            .addCase(storeReview.fulfilled, (state, action) => {
                state.isStoringReview = false
            })
            .addCase(storeReview.rejected, (state, action) => {
                state.isStoringReview = false
            })
            .addCase(storeReview.pending, (state, action) => {
                state.isStoringReview = true
            })
    },
})

export const {actions: userActions} = userSlice
export const {reducer: userReducer} = userSlice
