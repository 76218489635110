import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {LanguageLevel} from "../../types";
import {Pagination} from "src/entities/Country";
import {InputError} from "src/entities/Auth";

export const getData = (state: StateSchema): Array<LanguageLevel> => state.languageLevel.data
export const getPagination = (state: StateSchema): Pagination => state.languageLevel.pagination
export const getFetching = (state: StateSchema): boolean => state.languageLevel.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.languageLevel.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.languageLevel.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.languageLevel.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.languageLevel.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.languageLevel.updateErrors
export const getIsFetchingAll = (state: StateSchema): boolean => state.languageLevel.isFetchingAll
