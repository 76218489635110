import {MenuSchema} from "../../types/index";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: MenuSchema = {
    data: {
        closed: false,
    }
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setClosed: (state, action: PayloadAction<boolean>) => {
            state.data.closed = action.payload
        }
    }
})

export const {actions: menuActions} = menuSlice
export const {reducer: menuReducer} = menuSlice
