import {TestQuestionAnswer, TestQuestionAnswerSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getTestQuestionAnswers} from "../services/getTestQuestionAnswers";
import {storeTestQuestionAnswer} from "../services/storeTestQuestionAnswer";
import {updateTestQuestionAnswer} from "../services/updateTestQuestionAnswer";
import {getTestQuestionAnswer} from "../services/getTestQuestionAnswer";

const initialState: TestQuestionAnswerSchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined
}

export const testQuestionAnswerSlice = createSlice({
    name: 'testQuestionAnswer',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<TestQuestionAnswer>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTestQuestionAnswers.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getTestQuestionAnswers.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getTestQuestionAnswers.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getTestQuestionAnswer.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getTestQuestionAnswer.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getTestQuestionAnswer.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeTestQuestionAnswer.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeTestQuestionAnswer.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeTestQuestionAnswer.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateTestQuestionAnswer.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateTestQuestionAnswer.fulfilled, (state, action) => {
                state.isUpdating = false
            })
            .addCase(updateTestQuestionAnswer.rejected, (state, action) => {
                state.isUpdating = false
                //@ts-ignore
                state.updateErrors = action.payload
            })
    }
})

export const {actions: testQuestionAnswerActions} = testQuestionAnswerSlice
export const {reducer: testQuestionAnswerReducer} = testQuestionAnswerSlice
