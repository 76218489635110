import {Subject, SubjectSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getSubjects} from "../services/getSubjects";
import {getSubject} from "../services/getSubject";
import {storeSubject} from "../services/storeSubject";
import {updateSubject} from "../services/updateSubject";
import {getAllSubjects} from "../services/getAllSubjects";

const initialState: SubjectSchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined,
    isFetchingAll: false
}

export const subjectSlice = createSlice({
    name: 'subject',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<Subject>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getSubjects.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getSubjects.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getSubjects.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getSubject.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getSubject.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getSubject.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeSubject.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeSubject.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeSubject.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateSubject.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateSubject.fulfilled, (state, action) => {
                state.isUpdating = false
            })
            .addCase(updateSubject.rejected, (state, action) => {
                state.isUpdating = false
                //@ts-ignore
                state.updateErrors = action.payload
            })
            .addCase(getAllSubjects.pending, (state, action) => {
                state.isFetchingAll = true
            })
            .addCase(getAllSubjects.fulfilled, (state, action) => {
                state.isFetchingAll = false
            })
            .addCase(getAllSubjects.rejected, (state, action) => {
                state.isFetchingAll = false
            })
    }
})

export const {actions: subjectActions} = subjectSlice
export const {reducer: subjectReducer} = subjectSlice
