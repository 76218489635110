import {createAsyncThunk} from "@reduxjs/toolkit";
import {APP_URL} from "src/shared/constants/api";

export const storeLiterature = createAsyncThunk(
    'literature/storeLiterature',
    async (data: {name: string, description: string, subject: number, file: any}, {rejectWithValue, getState}) => {
        try {
            // @ts-ignore
            const csrfToken = getState().auth.data.csrfToken
            const formData = new FormData()
            formData.append('name', data.name)
            formData.append('description', data.description)
            formData.append('subject', `${data.subject}`)
            formData.append('file', data.file)

            const response = await fetch(APP_URL + '/api/admin/dictionaries/literatures', {
                method: 'POST',
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formData,
                credentials: 'include'
            })

            if (!response.ok) {
                // @ts-ignore
                const res = await response.json()
                return rejectWithValue(res.errors)
            } else {
                const res = await response.json()
                console.log({res})
            }
        } catch (error) {
            console.log({error})

            // @ts-ignore
            return rejectWithValue(error.response.data)
        }
    }
)
