import {Roles} from "src/shared/config/routes/roles";

export const can = (roles: Array<Roles>, userRole: string): boolean => {
    let can = false

    roles.forEach((role: Roles) => {
        if (role === userRole) {
            can = true
        }
    })
    return can
}
