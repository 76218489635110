import {createAsyncThunk} from "@reduxjs/toolkit";
import {APP_URL} from "src/shared/constants/api";
import {TutorGroupLessonDay} from "src/entities/TutorGroupLesson";

export const updateTutorGroupLesson = createAsyncThunk(
    'tutorCategoryGroupLesson/updateTutorGroupLesson',
    async (data: {
        id: number,
        group_name: string,
        start_date: string,
        end_date: string,
        days: TutorGroupLessonDay,
        level: string,
    }, {rejectWithValue, getState}) => {
        try {
            // @ts-ignore
            const csrfToken = getState().auth.data.csrfToken

            const response = await fetch(APP_URL + `/api/tutor/group-lessons/${data.id}`, {
                method: 'PUT',
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    group_name: data.group_name,
                    start_date: data.start_date,
                    end_date: data.end_date,
                    days: data.days,
                }),
                credentials: 'include'
            })

            if (!response.ok) {
                // @ts-ignore
                const res = await response.json()
                return rejectWithValue(res.errors)
            } else {
                const res = await response.json()
            }
        } catch (error) {
            console.log({error})

            // @ts-ignore
            return rejectWithValue(error.response.data)
        }
    }
)
