import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {TestQuestion} from "../../types";
import {InputError} from "src/entities/Auth";
import {Pagination} from "src/entities/Country";

export const getData = (state: StateSchema): Array<TestQuestion> => state.testQuestion.data
export const getPagination = (state: StateSchema): Pagination => state.testQuestion.pagination
export const getFetching = (state: StateSchema): boolean => state.testQuestion.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.testQuestion.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.testQuestion.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.testQuestion.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.testQuestion.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.testQuestion.updateErrors
