import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {TestQuestionAnswer} from "../../types";
import {InputError} from "src/entities/Auth";
import {Pagination} from "src/entities/Country";

export const getData = (state: StateSchema): Array<TestQuestionAnswer> => state.testQuestionAnswer.data
export const getPagination = (state: StateSchema): Pagination => state.testQuestionAnswer.pagination
export const getFetching = (state: StateSchema): boolean => state.testQuestionAnswer.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.testQuestionAnswer.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.testQuestionAnswer.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.testQuestionAnswer.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.testQuestionAnswer.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.testQuestionAnswer.updateErrors
