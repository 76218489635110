import {Language, LanguageSchema} from "../../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getLanguages} from "../services/getLanguages";
import {storeLanguage} from "../services/storeLanguage";
import {updateLanguage} from "../services/updateLanguage";
import {getLanguage} from "../services/getLanguage";
import {getAllLanguages} from "../services/getAllLanguages";

const initialState: LanguageSchema = {
    data: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page:10
    },
    fetching: false,
    fetchingOne: false,
    isStoring: false,
    storeErrors: undefined,
    isUpdating: false,
    updateErrors: undefined,
    isFetchingAll: false,
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<Language>) => {
            if (!state.data) {
                state.data = [action.payload]
            } else {
                state.data = [...state.data, action.payload]
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getLanguages.pending, (state, action) => {
                state.fetching = true
            })
            .addCase(getLanguages.fulfilled, (state, action) => {
                //@ts-ignore
                state.data = action.payload.data
                //@ts-ignore
                state.pagination = action.payload.pagination
                state.fetching = false
            })
            .addCase(getLanguages.rejected, (state, action) => {
                state.fetching = false
            })
            .addCase(getLanguage.pending, (state, action) => {
                state.fetchingOne = true
            })
            .addCase(getLanguage.fulfilled, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(getLanguage.rejected, (state, action) => {
                state.fetchingOne = false
            })
            .addCase(storeLanguage.pending, (state, action) => {
                state.isStoring = true
            })
            .addCase(storeLanguage.fulfilled, (state, action) => {
                state.isStoring = false
            })
            .addCase(storeLanguage.rejected, (state, action) => {
                state.isStoring = false
                //@ts-ignore
                state.storeErrors = action.payload
            })
            .addCase(updateLanguage.pending, (state, action) => {
                state.isUpdating = true
            })
            .addCase(updateLanguage.fulfilled, (state, action) => {
                state.isUpdating = false
            })
            .addCase(updateLanguage.rejected, (state, action) => {
                state.isUpdating = false
                //@ts-ignore
                state.updateErrors = action.payload
            })
            .addCase(getAllLanguages.pending, (state, action) => {
                state.isFetchingAll = true
            })
            .addCase(getAllLanguages.fulfilled, (state, action) => {
                state.isFetchingAll = false
            })
            .addCase(getAllLanguages.rejected, (state, action) => {
                state.isFetchingAll = false
            })
    }
})

export const {actions: languageActions} = languageSlice
export const {reducer: languageReducer} = languageSlice
