import classes from './index.module.css'
import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {getData, toastActions} from "src/entities/Toast";
import {useAppDispatch} from "src/shared/hooks/useAppDispatch";
const ToastProvider = (props: React.PropsWithChildren) => {
    const toast = useSelector(getData)
    const dispatch = useAppDispatch()
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    const hideToast = () => {
        dispatch(toastActions.setData(undefined))
    }

    useEffect(() => {
        if (toast) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                if (toast) {
                    hideToast()
                }
            }, toast?.duration ? toast.duration : 3000)
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [toast])

    return (
        <div className={classes.toastContainer}>
            {props.children}
            {toast && (
                <div className={classes.toast}>
                    <div className={classes.toastWrapper}>
                        <div className={classes.toastContent}>
                            {toast.content}
                        </div>
                        <button
                            className={classes.close}
                            onClick={hideToast}
                        >
                            <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                            </svg>
                        </button>
                        <div
                            key={toast.content}
                            className={classes.progressBar}
                            style={{animationDuration: toast?.duration ? `${toast.duration}ms` : '3000ms'}}
                        ></div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ToastProvider
