import { Popover } from 'antd';
import Styled from 'styled-components';

const Content = Styled.div`  
  a, .span {
      display: flex;
      color: gray;
      padding: 6px 12px;
      text-align: left;
      span {
        line-height: 1.25;
        padding-right: 12px;
      }
  }
  a:hover {
    background: rgba(130, 49, 211, 0.02);
    padding-left: 12px;
    color: #8231D3;
  }
  
`;

const Title = Styled.p`
  text-align: ${({ theme }) => (theme.rtl ? 'right' : 'left')};
  margin: 0;
`;
const PopoverStyle = Styled(Popover)` 
  
`;
export { Content, PopoverStyle, Title };
