import classes from './index.module.css'
import React, {useEffect, useState} from "react";
import {Sidebar} from "src/widgets/Sidebar";
import {Link} from "react-router-dom";
import {RoutesConfig} from "src/shared/config/routes";
import {ReactComponent as LeftBar} from "src/shared/assets/icons/left-bar.svg"
import Brand from "src/shared/assets/images/logo.png";
import {useSelector} from "react-redux";
import {getClosed, menuActions} from "src/entities/Menu";
import {Button} from "src/shared/ui/Button";
import {getAuthUserData} from "src/entities/User";
import {useAppDispatch} from "src/shared/hooks/useAppDispatch";
import {className} from "src/shared/utils/className";
import { Col, Layout, Row, Button as AntButton } from 'antd';
import {AuthInfo} from "src/shared/ui/AuthInfo";
import UiEllipsisV from "src/shared/ui/UiEllipsisV";
import Styled from "styled-components";

const SmallScreenAuthInfo = Styled.div`
    background-color: rgb(255, 255, 255);
    width: 100%;
    position: fixed;
    margin-top: 72px;
    top: 0px;
    left: 0px;
    transition: all 0.3s ease 0s;
    opacity: 1;
    z-index: 1;
    box-shadow: rgba(146, 153, 184, 0.063) 0px 2px 30px;
    padding: 10px 0px;
    @media only screen and (max-width: 767px){
        padding: 10px 15px;
    }
`;

const AppLayout = (props: React.PropsWithChildren) => {
    const { Header, Sider, Content } = Layout;
    const authData = useSelector(getAuthUserData)
    const dispatch = useAppDispatch()
    const closed = useSelector(getClosed)
    const [hide, setHide] = useState(true)

    const toggleShow = () => {
        dispatch(menuActions.setClosed(!closed))
    }

    useEffect(() => {
        if (window.innerWidth < 576) {
            dispatch(menuActions.setClosed(true))
        }
    }, [])

    return (
        <div className={classes.adminLayout}>
            <Header
                style={{
                    position: 'fixed',
                    width: '100%',
                    top: 0,
                    left: 0,
                    background: '#fff',
                    zIndex: 5
                }}
            >
                <div className="ninjadash-header-content flex">
                    <div className="ninjadash-header-content__left">
                        <div className="navbar-brand">
                            <Link
                                className={window.innerWidth > 991 ? 'ninjadash-logo top-menu' : 'ninjadash-logo'}
                                to={RoutesConfig.main.path}
                            >
                                <img src="/images/logo-dark.svg" alt="Brand"/>
                            </Link>
                            <AntButton type="link" onClick={toggleShow}>
                                <LeftBar />
                            </AntButton>
                            <p className={classes.brandText}>Раскройте свой потенциал</p>
                        </div>
                    </div>
                    <div className="ninjadash-header-content__right flex">
                        <div className="ninjadash-nav-actions">
                            <div className="top-right-wrap d-flex">
                                <AuthInfo
                                    username={authData?.name}
                                    role={authData?.role}
                                />
                            </div>
                        </div>
                        <div className="ninjadash-header-content__mobile">
                            <div className="ninjadash-mobile-action">
                                <Link className="btn-auth" onClick={() => setHide(prev => !prev)} to="#">
                                    <UiEllipsisV size={18} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Header>
            <div className="ninjadash-header-more">
                <Row>
                    <Col md={0} sm={24} xs={24}>
                        <div className="ninjadash-header-more-inner">
                            {!hide ? (
                                <SmallScreenAuthInfo>
                                    <AuthInfo
                                        username={authData?.name}
                                        role={authData?.role}
                                    />
                                </SmallScreenAuthInfo>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </div>
            {/*<header className={classes.header}>*/}
            {/*    <div className={classes.left}>*/}
            {/*        <Link to={RoutesConfig.tutors.path}>*/}
            {/*            <img*/}
            {/*                src={Brand}*/}
            {/*                alt="Brand"*/}
            {/*                className={classes.brand}*/}
            {/*            />*/}
            {/*        </Link>*/}
            {/*        <button onClick={toggleShow}>*/}
            {/*            <LeftBar />*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    <div className={classes.right}>*/}
            {/*        <Button>*/}
            {/*            <Link to={RoutesConfig.tutors.path}>*/}
            {/*                К учителям*/}
            {/*            </Link>*/}
            {/*        </Button>*/}
            {/*        <h2 className={classes.name}>{authData?.name}</h2>*/}
            {/*    </div>*/}
            {/*</header>*/}
            <Sidebar />
            <div className={className(classes.children, {
                [classes.closed]: closed
            })}>
                {props.children}
            </div>
        </div>
    )
}

export default AppLayout
