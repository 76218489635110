import classes from "./index.module.css";
import {Button} from "src/shared/ui/Button";
import React from "react";
import {useSelector} from "react-redux";
import {getAuthUserData} from "src/entities/User";
import {Link, useNavigate} from "react-router-dom";
import {RoutesConfig} from "src/shared/config/routes";
import {ButtonThemes} from "src/shared/ui/Button/ui";

const AuthSidebar = () => {
    const authData = useSelector(getAuthUserData)

    const navigate = useNavigate()

    const onTutorsPage = () => {
        navigate(RoutesConfig.tutors.path)
    }

    const onLoginPage = () => {
        navigate(RoutesConfig.login.path)
    }

    const onPofile = () => {
        switch (authData?.role) {
            case 'admin':
                navigate(RoutesConfig.main_admin.path)
                break
            case 'tutor':
                navigate(RoutesConfig.main_tutor.path)
                break
            case 'student':
                navigate(RoutesConfig.main_student.path)
                break
            case 'moderator':
                navigate(RoutesConfig.main_moderator.path)
                break
        }
    }

    return (
        <header className={classes.header}>
            <div className={classes.brand}>
                <Link to={RoutesConfig.main.path}>
                    <img src="/images/logo-dark.svg" alt="Brand"/>
                </Link>
                <p className={classes.brandText}>Раскрой свой потенциал</p>
            </div>
            <div className={classes.actions}>
                <Button
                    onClick={onTutorsPage}
                    cls={classes.action}
                >
                    Найти репетитора
                </Button>
                {authData ? (
                    <Button
                        onClick={onPofile}
                        themes={[ButtonThemes.DECORATED]}
                        cls={classes.action}
                    >
                        {authData?.name}
                    </Button>
                ) : (
                    <Button
                        onClick={onLoginPage}
                        cls={classes.action}
                    >Логин</Button>
                )}
            </div>
        </header>
    )
}

export default AuthSidebar
