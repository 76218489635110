import {StateSchema} from "src/app/providers/Store/config/StateSchema";
import {Tutor, User, TutorStudent} from "src/entities/User/types";
import {Pagination} from "src/entities/Country";
import {InputError} from "src/entities/Auth";

export const getUsers = (state: StateSchema): Array<User> => state.user.data
export const getPagination = (state: StateSchema): Pagination => state.user.pagination
export const getFetching = (state: StateSchema): boolean => state.user.fetching
export const getFetchingOne = (state: StateSchema): boolean => state.user.fetchingOne
export const getIsStoring = (state: StateSchema): boolean => state.user.isStoring
export const getStoreErrors = (state: StateSchema): InputError | undefined => state.user.storeErrors
export const getIsUpdating = (state: StateSchema): boolean => state.user.isUpdating
export const getUpdateErrors = (state: StateSchema): InputError | undefined => state.user.updateErrors
export const getTutorStepsErrors = (state: StateSchema): InputError | undefined => state.user.tutorStepsErrors
export const getIsTutorRequesting = (state: StateSchema): boolean => state.user.isTutorRequesting
export const getTutorRequest = (state: StateSchema): any => state.user.tutorRequest
export const getIsApproving = (state: StateSchema): boolean => state.user.isApproving
export const getIsFetchingTutors = (state: StateSchema): boolean => state.user.isFetchingTutors
export const getIsFetchingTutorRequests = (state: StateSchema): boolean => state.user.isFetchingTutorRequests
export const getTutorRequests = (state: StateSchema): any => state.user.tutorRequests
export const getIsFetchingTutorRequest = (state: StateSchema): any => state.user.isFetchingTutorRequest
export const getIsStoringModeratorRequest = (state: StateSchema): any => state.user.isStoringModeratorRequest
export const getTutors = (state: StateSchema): Array<Tutor> => state.user.tutors
export const getIsFetchingTutor = (state: StateSchema): boolean => state.user.isFetchingTutor
export const getIsFetchingTimezones = (state: StateSchema): boolean => state.user.isFetchingTimezones
export const getIsFetchingAuthData = (state: StateSchema): boolean => state.user.isFetchingAuthData
export const getIsFetchingStepperRequest = (state: StateSchema): boolean => state.user.isFetchingStepperRequest
export const getIsSubmitting = (state: StateSchema): boolean => state.user.isSubmitting
export const getIsBookingLesson = (state: StateSchema): boolean => state.user.isBookingLesson
export const getIsFetchingStudentRequests = (state: StateSchema): boolean => state.user.isFetchingStudentRequests
export const getStudentRequests = (state: StateSchema): any => state.user.studentRequests
export const getGroupRequests = (state: StateSchema): any => state.user.groupRequests
export const getTrialRequests = (state: StateSchema): any => state.user.trialRequests
export const getIsFetchingStudentRequest = (state: StateSchema): any => state.user.isFetchingStudentRequest
export const getIsStoringStudentRequest = (state: StateSchema): boolean => state.user.isStoringStudentRequest
export const getTutorErrorMessage = (state: StateSchema): string => state.user.getTutorError
export const getIsFetchingTutorGroupLessons = (state: StateSchema): boolean => state.user.isFetchingTutorGroupLessons
export const getIsFetchingTutorSchedules = (state: StateSchema): boolean => state.user.isFetchingTutorSchedules
export const getIsFetchingStudentTests = (state: StateSchema): boolean => state.user.isFetchingStudentTests
export const getIsFetchingStudentTest = (state: StateSchema): boolean => state.user.isFetchingStudentTest
export const getIsCompletingStudentTest = (state: StateSchema): boolean => state.user.isCompletingStudentTest
export const getIsFetchingStudentLiteratures = (state: StateSchema): boolean => state.user.isFetchingStudentLiteratures
export const getIsFetchingStudentPendingLessons = (state: StateSchema): boolean => state.user.isFetchingStudentPendingLessons
export const getIsFetchingAllStudentLessons = (state: StateSchema): boolean => state.user.isFetchingAllStudentLessons
export const getIsFetchingAllLiteratures = (state: StateSchema): boolean => state.user.isFetchingAllLiteratures
export const getIsFetchingAllTests = (state: StateSchema): boolean => state.user.isFetchingAllTests
export const getIsSavingTutorLiteratures = (state: StateSchema): boolean => state.user.isSavingTutorLiteratures
export const getIsSavingTutorTests = (state: StateSchema): boolean => state.user.isSavingTutorTests
export const getIsDeletingTutorLiterature = (state: StateSchema): boolean => state.user.isDeletingTutorLiterature
export const getIsDeletingTutorTest = (state: StateSchema): boolean => state.user.isDeletingTutorTest
export const getIsFetchingTutorLiteratures = (state: StateSchema): boolean => state.user.isFetchingTutorLiteratures
export const getIsFetchingTutorTests = (state: StateSchema): boolean => state.user.isFetchingTutorTests
export const getIsFetchingTutorTest = (state: StateSchema): boolean => state.user.isFetchingTutorTest
export const getIsDecliningStudentRequest = (state: StateSchema): boolean => state.user.isDecliningStudentRequest
export const getIsFetchingStudentPendingLesson = (state: StateSchema): boolean => state.user.isFetchingStudentPendingLesson
export const getIsFetchingAllTutorLessons = (state: StateSchema): boolean => state.user.isFetchingAllTutorLessons
export const getIsFetchingTutorLesson = (state: StateSchema): boolean => state.user.isFetchingTutorLesson
export const getIsFetchingStudentCurrentLesson = (state: StateSchema): boolean => state.user.isFetchingStudentCurrentLesson
export const getIsFetchingStudentLesson = (state: StateSchema): boolean => state.user.isFetchingStudentLesson
export const getIsFetchingTutorStudents = (state: StateSchema): boolean => state.user.isFetchingTutorStudents
export const getTutorStudents = (state: StateSchema): Array<TutorStudent> => state.user.tutorStudents
export const getTutorTests = (state: StateSchema): Array<any> => state.user.tutorTests
export const getIsFetchingTutorStep = (state: StateSchema): boolean => state.user.isFetchingTutorStep
export const getIsUpdatingTutorStep = (state: StateSchema): boolean => state.user.isUpdatingTutorStep
export const getIsStoringReview = (state: StateSchema): boolean => state.user.isStoringReview
