import classes from './index.module.css'
import React, {useMemo} from "react";
import {className} from "src/shared/utils/className";
import {
    SidebarItem,
    AdminSidebarItems,
    StudentSidebarItems,
    TutorSidebarItems,
    ModeratorSidebarItems
} from "src/shared/config/routes/sidebar";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as Exit} from 'src/shared/assets/icons/exit.svg'
import {useAppDispatch} from "src/shared/hooks/useAppDispatch";
import {logout} from "src/entities/Auth/model/services/logout";
import {RoutesPath} from "src/shared/config/routes";
import {getAuthUserData, userActions} from "src/entities/User";
import {useSelector} from "react-redux";
import {getClosed} from "src/entities/Menu";

const Sidebar = (props: React.PropsWithChildren) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const authData = useSelector(getAuthUserData)
    const closed= useSelector(getClosed)
    const location = useLocation()

    const sidebarItems = useMemo(() => {
        let items: Array<SidebarItem> = []
        switch (authData?.role) {
            case 'admin':
                items = AdminSidebarItems
                break
            case 'student':
                items = StudentSidebarItems
                break
            case 'tutor':
                items = TutorSidebarItems
                break
            case 'moderator':
                items = ModeratorSidebarItems
                break
        }

        let path = location.pathname.split('/')
        const realPath = path[path.length - 1]

        return items.map((item: SidebarItem) => {
            let itemPath: any = item.path.split('/')
            itemPath = itemPath[itemPath.length - 1]

            return (
                <Link
                    key={item.path}
                    to={item.path}
                    className={classes.link}
                >
                    <li className={className(classes.item, {
                        [classes.closedItem]: closed,
                        [classes.activeItem]: itemPath === realPath,
                    })}>
                        {item.icon()}
                        {!closed ? (
                            item.label
                        ) : null}
                    </li>
                </Link>
            )
        })
    }, [authData, closed, location])

    const onLogout = async () => {
        localStorage.removeItem('token') //TODO: Remove it when HTTP-only cookie will be used
        await dispatch(userActions.setAuthData(undefined))
        await dispatch(logout())
        navigate(RoutesPath.login)
    }

    return (
        <div className={className(classes.sidebar, {
            [classes.closed]: closed,
        })}>
            <ul className={classes.items}>
                {sidebarItems}
                <button
                    onClick={onLogout}
                    className={className(classes.item, {
                        [classes.closedItem]: closed
                    }, [classes.logout])}
                >
                    <Exit width={20} height={20} />
                    {!closed ? 'Выход' : null}
                </button>
            </ul>
        </div>
    )
}

export default Sidebar
