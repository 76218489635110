import {createAsyncThunk} from "@reduxjs/toolkit";
import {APP_URL} from "src/shared/constants/api";

export const getTutors = createAsyncThunk(
    'user/getTutors',
    async (data: {
        country?: number
        priceRange?: string
        languages?: Array<number | string>
        q?: string
    } | undefined, {getState, rejectWithValue}) => {
        try {
            // @ts-ignore
            const csrfToken = getState().auth.data.csrfToken
            let uri = `/api/tutors`

            if (data) {
                if (data.country) {
                    uri += `?country=${data.country}`
                }
                if (data.priceRange) {
                    if (uri.includes('?')) {
                        uri += `&priceRange=${data.priceRange}`
                    } else {
                        uri += `?priceRange=${data.priceRange}`
                    }
                }
                if (
                    data.languages &&
                    data.languages.length &&
                    data.languages.filter(language => language !== '').length
                ) {
                    for (let i = 0; i < data.languages.length; i++) {
                        if (uri.includes('?')) {
                            uri += `&languages[]=${data.languages[i]}`
                        } else {
                            uri += `?languages[]=${data.languages[i]}`
                        }
                    }
                }
                    if (uri.includes('?')) {
                        uri += `&q=${data.q}`
                    } else {
                        uri += `?q=${data.q}`
                    }
            }

            const response = await fetch(APP_URL + uri, {
                method: 'GET',
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                credentials: 'include'
            })

            if (!response.ok) {
                // @ts-ignore
                const res = await response.json()
                console.log({res})
                // return rejectWithValue(data.errors)
            } else {
                const res = await response.json()
                return res
            }
        } catch (error) {
            console.log({error})

            // @ts-ignore
            return rejectWithValue(error.response.data)
        }
    }
)
